import { Switch } from 'react-router-dom'
import { Role } from 'models'
import { PrivateRoute } from 'components/PrivateRoute'
import { NotFoundPage } from 'components/NotFoundPage'
import { CommingSoonPage } from 'components/CommingSoonPage'

import {
  CreateOrder,
  EditOrder,
  ListOrders,
  ShowConnectivityOrder,
  ShowHardwareOrder
} from 'modules/Orders'
import {
  CreateSubscription,
  EditSubscription,
  ListSubscriptions,
  ShowSubscription
} from 'modules/Subscriptions'
import { ListPlans, CreatePlan, EditPlan, ViewPlan } from 'modules/Plans'
import { ListStaff, ViewStaff, EditStaff, CreateStaff } from 'modules/Staff'
import { ListChips, CreateChips } from 'modules/Items/Chips'
import { ListDevices, CreateDevices } from 'modules/Items/Devices'
import {
  ListCoupons,
  ShowCoupon,
  CreateCoupon,
  EditCoupon
} from 'modules/Coupons'
import {
  ListAccess,
  CreateAccess,
  EditAccess,
  ViewAccess
} from 'modules/AccessPlans'
import {
  CreateClient,
  EditClient,
  ListClients,
  ViewClient
} from 'modules/Clients'
import { ClientViewProvider } from 'modules/Clients/contexts/ClientViewContext'
import { StaffViewProvider } from 'modules/Staff/contexts/StaffViewContext'
import { ClientEditProvider } from 'modules/Clients/contexts/ClientEditContext'
import { StaffEditProvider } from 'modules/Staff/contexts/StaffEditContext'
import { PlanViewProvider } from 'modules/Plans/contexts/PlanViewContext'
import { AccessPlanViewProvider } from 'modules/AccessPlans/contexts/AccessPlanViewContext'
import { ListUsersByClients } from 'modules/LoginAsClient'
import { ListRequirements } from 'modules/Items/Requirements'
import { ListInvoices, ShowInvoice } from 'modules/Invoices'

export function Routes() {
  return (
    <Switch>
      <PrivateRoute exact path="/">
        <CommingSoonPage />
      </PrivateRoute>
      <PrivateRoute exact path="/plans">
        <ListPlans />
      </PrivateRoute>
      <PrivateRoute exact path="/plans/create" roles={[]}>
        <CreatePlan />
      </PrivateRoute>
      <PrivateRoute exact path="/plans/:planId">
        <EditPlan />
      </PrivateRoute>
      <PrivateRoute exact path="/plans/:planId/view/general">
        <PlanViewProvider>
          <ViewPlan />
        </PlanViewProvider>
      </PrivateRoute>
      <PrivateRoute exact path="/staff-members">
        <ListStaff />
      </PrivateRoute>
      <PrivateRoute exact path="/staff-members/create" roles={[Role.MANAGER]}>
        <CreateStaff />
      </PrivateRoute>
      <PrivateRoute exact path="/staff-members/:staffId/view/:viewTab">
        <StaffViewProvider>
          <ViewStaff />
        </StaffViewProvider>
      </PrivateRoute>
      <PrivateRoute exact path="/staff-members/:staffId/edit/:editTab">
        <StaffEditProvider>
          <EditStaff />
        </StaffEditProvider>
      </PrivateRoute>
      <PrivateRoute exact path="/access-plans">
        <ListAccess />
      </PrivateRoute>
      <PrivateRoute exact path="/access-plans/create" roles={[]}>
        <CreateAccess />
      </PrivateRoute>
      <PrivateRoute exact path="/access-plans/:accessId">
        <EditAccess />
      </PrivateRoute>
      <PrivateRoute exact path="/access-plans/:accessId/view/general">
        <AccessPlanViewProvider>
          <ViewAccess />
        </AccessPlanViewProvider>
      </PrivateRoute>
      <PrivateRoute exact path="/clients">
        <ListClients />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/clients/create"
        roles={[Role.MANAGER, Role.FINANCE, Role.SALES]}
      >
        <CreateClient />
      </PrivateRoute>
      <PrivateRoute exact path="/clients/:clientId/view/:viewTab">
        <ClientViewProvider>
          <ViewClient />
        </ClientViewProvider>
      </PrivateRoute>
      <PrivateRoute exact path="/clients/:clientId/edit/:editTab">
        <ClientEditProvider>
          <EditClient />
        </ClientEditProvider>
      </PrivateRoute>
      <PrivateRoute exact path="/invoices">
        <ListInvoices />
      </PrivateRoute>
      <PrivateRoute exact path="/invoices/:invoiceId">
        <ShowInvoice />
      </PrivateRoute>
      <PrivateRoute exact path="/coupons">
        <ListCoupons />
      </PrivateRoute>
      <PrivateRoute exact path="/coupons/create">
        <CreateCoupon />
      </PrivateRoute>
      <PrivateRoute exact path="/coupons/:couponId/view/general">
        <ShowCoupon />
      </PrivateRoute>
      <PrivateRoute exact path="/coupons/:couponId">
        <EditCoupon />
      </PrivateRoute>
      <PrivateRoute exact path="/orders">
        <ListOrders />
      </PrivateRoute>
      <PrivateRoute exact path="/subscriptions">
        <ListSubscriptions />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/orders/create"
        roles={[Role.MANAGER, Role.SALES]}
      >
        <CreateOrder />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/subscriptions/create"
        roles={[Role.MANAGER, Role.SALES]}
      >
        <CreateSubscription />
      </PrivateRoute>
      <PrivateRoute exact path="/orders/:orderId">
        <EditOrder />
      </PrivateRoute>
      <PrivateRoute exact path="/orders/:orderId/chips">
        <ShowConnectivityOrder />
      </PrivateRoute>
      <PrivateRoute exact path="/orders/:orderId/devices">
        <ShowHardwareOrder />
      </PrivateRoute>
      <PrivateRoute exact path="/subscriptions/:subscriptionId">
        <EditSubscription />
      </PrivateRoute>
      <PrivateRoute exact path="/subscriptions/:subscriptionId/view">
        <ShowSubscription />
      </PrivateRoute>
      <PrivateRoute exact path="/chips">
        <ListChips />
      </PrivateRoute>
      <PrivateRoute exact path="/chips/create">
        <CreateChips />
      </PrivateRoute>
      <PrivateRoute exact path="/requirements" roles={[]}>
        <ListRequirements />
      </PrivateRoute>
      <PrivateRoute exact path="/devices">
        <ListDevices />
      </PrivateRoute>
      <PrivateRoute exact path="/devices/create">
        <CreateDevices />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/support"
        roles={[Role.SUPPORT, Role.MANAGER, Role.SALES, Role.LOGISTICS]}
      >
        <ListUsersByClients />
      </PrivateRoute>
      <PrivateRoute path="*">
        <NotFoundPage />
      </PrivateRoute>
    </Switch>
  )
}
