import { list as baseList, patch as basePatch } from 'services/billing'
import { ListResult, QueryOptions, ReadResult } from 'interfaces/queryOptions'
import {
  ChipsRequirement,
  ChipsRequirementStatuses
} from 'models/chips_requirement'

export async function list(
  options: QueryOptions = {}
): Promise<ListResult<ChipsRequirement>> {
  const response = await baseList<ChipsRequirement>(
    '/chips/requirements',
    options
  )
  return response
}

export async function update(
  ids: string[] | number[],
  status: ChipsRequirementStatuses
): Promise<ReadResult<ChipsRequirement>> {
  const response = await basePatch('/chips/requirements', {
    data: ids.map((id) => ({
      type: 'chip_requirements',
      id: id,
      attributes: {
        status
      }
    }))
  })
  return response
}
