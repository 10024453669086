import { Dot } from 'components/Dot'
import { Modal } from 'components/Modal'

interface Props {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export function Info({ isOpen, setIsOpen }: Props) {
  return (
    <Modal open={isOpen} title="Informações" onClose={() => setIsOpen(!isOpen)}>
      <div>
        <div className="mt-2 text-sm text-gray-500">
          <p>
            Rastreadores ativados (<Dot color="emerald" />) podem ser vendidos.
          </p>
        </div>
        <p className="mt-2 text-sm text-gray-500">
          Rastreadores desativados (<Dot color="red" />) podem ser cancelados,
          devolvidos ou vendidos.
        </p>
        <p className="mt-3 text-sm text-gray-500">
          Rastreadores cancelados ou vendidos não podem ser alterados.
        </p>
      </div>
    </Modal>
  )
}
