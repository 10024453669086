import { Translations } from 'types'

export const translations: Translations = {
  client_status: {
    active: 'Ativo',
    not_renewed: 'Não renovado',
    overdue: 'Atrasado',
    canceled: 'Cancelado',
    pending: 'Pendente',
    restricted: 'Bloqueado'
  },
  requirement_status: {
    open: 'Aberta',
    in_progress: 'Em progresso',
    executed: 'Concluída',
    refused: 'Recusada'
  },
  requirement_type: {
    cancelation: 'Cancelamento',
    suspended: 'Suspensão',
    activation: 'Ativação'
  },
  plan_type: {
    software: 'Software',
    connectivity: 'Conectividade',
    hardware: 'Hardware',
    other: 'Outros'
  },
  plan_nature: {
    service: 'Serviço',
    good: 'Bem'
  },
  plan_interval: {
    day: 'Diário',
    week: 'Semanal',
    month: 'Mensal',
    year: 'Anual'
  },
  order_status: {
    pending: 'Pendente',
    approved: 'Aprovado',
    in_line: 'Na fila',
    in_progress: 'Em progresso',
    updating_fiscal_data: 'Emitindo nota fiscal',
    ready_for_pick_up: 'Pronto para entrega',
    shipping: 'Em envio',
    delivered: 'Entregue',
    canceled: 'Cancelado'
  },
  order_upcoming_status: {
    pending: 'Pendente',
    approved: 'Aprovar',
    in_line: 'Enfileirar',
    in_progress: 'Preparar',
    updating_fiscal_data: 'Emitir nota fiscal',
    ready_for_pick_up: 'Empacotar',
    shipping: 'Enviar',
    delivered: 'Entregar',
    canceled: 'Cancelar'
  },
  item_status: {
    ordered: 'Pedido',
    returned: 'Devolvido',
    canceled: 'Cancelado',
    repaired: 'Reparado',
    ownership_changed: 'Troca de titularidade',
    suspended: 'Suspenso'
  },
  shipping_methods: {
    undefined: 'Indefinido',
    pick_up: 'Retirar na Softruck',
    shipping: 'Entrega padrão'
  },
  write_off_motives: {
    rent: 'Aluguel',
    leasing: 'Comodato',
    sale: 'Venda',
    loss: 'Perda',
    asset_sale: 'Venda de ativo',
    returned: 'Devolvido'
  },
  duration_mode: {
    forever: 'Permanente',
    repeating: 'Recorrente',
    once: 'Única'
  },
  coupon_interval: {
    day: 'dia(s)',
    week: 'semana(s)',
    month: 'mês(es)',
    year: 'ano(s)'
  }
}
