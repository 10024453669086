export function formatYearMonth(yearMonth: string): string {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ]

  if (!/^\d{6}$/.test(yearMonth)) {
    throw new Error('Formato inválido. Use "AAAAMM", por exemplo: "202408".')
  }

  const year = yearMonth.slice(0, 4)
  const monthIndex = parseInt(yearMonth.slice(4, 6), 10) - 1

  if (monthIndex < 0 || monthIndex > 11) {
    throw new Error('Mês inválido. Deve estar entre 01 e 12.')
  }

  return `${months[monthIndex]} de ${year}`
}
