import { ListResult, QueryOptions } from 'interfaces/queryOptions'
import { Invoice } from 'models/invoice'
import { InvoiceItem } from 'models/invoiceItem'
import { list as baseList } from 'services/billing'
import { show as baseShow } from 'services/billing'

export async function list(
  options: QueryOptions = {}
): Promise<ListResult<Invoice>> {
  const response = await baseList<Invoice>('/invoices', options)
  return response
}

export async function listItemsByInvoiceId(
  invoiceId: number,
  options: QueryOptions = {}
): Promise<ListResult<InvoiceItem>> {
  const response = await baseList<InvoiceItem>(
    `/invoices/${invoiceId}/invoice-items`,
    options
  )
  return response
}

export async function show(
  invoiceId: number,
  options: QueryOptions = {}
): Promise<Partial<Invoice>> {
  const response = await baseShow<Invoice>('/invoices', invoiceId, options)
  return {
    ...response.attributes,
    id: response.id,
    client_id: response.relationships?.client?.id,
    relationships: response.relationships
  }
}
