import { useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import { pt } from 'date-fns/locale'
import { addDays, endOfDay, format, startOfDay } from 'date-fns'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { QueryFilter } from 'interfaces/queryOptions'

interface Props {
  filters: QueryFilter[]
  onFilter: (key: string, value: string[]) => void
  onClose: () => void
  cleanRange: () => void
  columnName: string
}

export function DateRangePickerComponent({
  filters,
  onFilter,
  onClose,
  cleanRange,
  columnName
}: Props) {
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(
        `${
          filters?.find((f) => f.key === columnName && f.op === 'gte')?.value ||
          new Date().toISOString().split('T')[0]
        }T00:00:00`
      ),
      endDate: new Date(
        `${
          filters?.find((f) => f.key === columnName && f.op === 'lte')?.value ||
          new Date().toISOString().split('T')[0]
        }T23:59:59`
      ),
      key: 'selection'
    }
  ])

  const handleDateChange = (ranges: any) => {
    setDateRange([
      {
        ...ranges.selection,
        startDate: startOfDay(ranges.selection.startDate),
        endDate: endOfDay(ranges.selection.endDate)
      }
    ])
  }

  const applyFilter = () => {
    const { startDate, endDate } = dateRange[0] || {}

    if (startDate && endDate) {
      const adjustedEndDate = addDays(endDate, 1)

      onFilter(columnName, [
        format(startDate, 'yyyy-MM-dd'),
        format(adjustedEndDate, 'yyyy-MM-dd')
      ])
      onClose()
    }
  }

  return (
    <div className="w-full z-50 bg-white py-2 border border-gray rounded-md">
      <DateRangePicker
        ranges={dateRange}
        onChange={handleDateChange}
        moveRangeOnFirstSelection={false}
        showDateDisplay={false}
        showPreview={false}
        months={1}
        direction="horizontal"
        locale={pt}
        rangeColors={['#16a34a', '#16a34a', '#16a34a']}
      />
      <div className="flex justify-end gap-2 mt-2 pr-2">
        <button
          onClick={() => {
            onClose()
            cleanRange()
          }}
          className="px-4 py-1 border shadow-sm rounded-md text-sm font-medium bg-gray-200"
        >
          Limpar
        </button>
        <button
          onClick={applyFilter}
          className="px-4 py-1 border shadow-md rounded-md text-sm bg-emerald-600 font-medium text-white"
        >
          Aplicar
        </button>
      </div>
    </div>
  )
}
