import { ChipsRequirementStatuses } from 'models/chips_requirement'
import { useCallback, useEffect, useState } from 'react'
import { classNames } from 'utils'

type Tab = {
  name: string
  value: string
  current: boolean
}

const tabOptions = [
  { name: 'Abertas', value: 'open', current: true },
  { name: 'Em progresso', value: 'in_progress', current: false },
  { name: 'Executadas', value: 'executed', current: false },
  { name: 'Recusadas', value: 'refused', current: false }
]

interface Props {
  onChange: (value: string) => any
  currentTab: ChipsRequirementStatuses
}

export function RequirementTabs({ onChange, currentTab }: Props) {
  const [tabs, setTabs] = useState<Tab[]>(tabOptions)

  useEffect(() => {
    setTabs((prevTabs) =>
      prevTabs.map((tab) => ({
        ...tab,
        current: tab.value === currentTab
      }))
    )
  }, [currentTab])

  const onSelect = useCallback(
    (tab: Tab) => {
      const beforeIdx = tabs.findIndex((t) => t.current)
      const selectIdx = tabs.findIndex((t) => t.value === tab.value)

      tabs[beforeIdx].current = false
      tabs[selectIdx].current = true

      setTabs([...tabs])

      onChange(tabs[selectIdx].value)
    },
    [onChange, tabs]
  )

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-emerald-500 focus:border-emerald-500 border-gray-300 rounded-md"
          defaultValue={tabs.find((tab) => tab.current)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav
          className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <div
              key={tab.name}
              onClick={() => onSelect(tab)}
              className={classNames(
                tab.current
                  ? 'bg-gray-50 text-gray-900'
                  : 'bg-white text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.current ? 'bg-emerald-500' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </div>
          ))}
        </nav>
      </div>
    </div>
  )
}
