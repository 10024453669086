import config from 'config'
import { useURLParams } from 'hooks/useURLParams'
import { QueryFilter, ReadResult } from 'interfaces/queryOptions'
import { Invoice, InvoiceStatus } from 'models/invoice'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { list } from '../services/read'
import { toast } from 'react-toastify'
import { PageHeader } from 'components/PageHeader'
import { SearchBar } from 'components/Search'
import { SimpleCustomSelect } from 'components/FormInputs'
import { LoadingCircle } from 'components/Loading'
import { SortDownIcon } from 'components/Table/SortDownIcon'
import { SorUptIcon } from 'components/Table/SortUpIcon'
import { SortIcon } from 'components/Table/SortIcon'
import { Label } from 'components/Label'
import { translations } from '../translations'
import { amount, currency, date, document } from 'utils'
import { BadgeCheckIcon, EyeIcon } from '@heroicons/react/outline'
import { Pagination } from 'components/Pagination'
import { formatYearMonth } from '../helpers'
import { formatDate } from 'utils/datetime'
import { DateRangePickerComponent } from 'components/Date/DateRangePicker'

const statusFilters = [
  { value: '', label: 'Selecione um estado' },
  { value: InvoiceStatus.draft, label: 'Rascunho' },
  { value: InvoiceStatus.open, label: 'Aberta' },
  { value: InvoiceStatus.paid, label: 'Paga' },
  { value: InvoiceStatus.uncollectible, label: 'Inadimplente' },
  { value: InvoiceStatus.canceled, label: 'Cancelada' }
]

const removeFilter = (filters: QueryFilter[], key: string): QueryFilter[] => {
  if (key === 'due_at') {
    return filters.filter((filter) => filter.key !== 'due_at')
  } else {
    return filters.filter((filter) => filter.key === 'due_at')
  }
}

interface Props {
  clientId?: number | string
}

export function List({ clientId }: Props) {
  const { replace } = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(() => {
    const params = {
      filters: [],
      search: ''
    }

    const search = query.get('search')
    const filters = query.get('q')

    if (search) params.search = search
    if (filters) params.filters = JSON.parse(atob(filters))

    return params
  }, [query])

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [search, setSearch] = useState(queryParams.search)
  const [filters, setFilters] = useState<QueryFilter[]>(queryParams.filters)

  const [sort, setSort] = useState<Record<string, number>>()
  const sortableValues = ['client.account_name', 'amount_due', 'due_at']
  const [sortedValue, setSortedValue] = useState<string>()
  const [isSortedDesc, setIsSortedDesc] = useState(true)

  const [total, setTotal] = useState(0)
  const [invoices, setInvoices] = useState<ReadResult<Invoice>[]>([])

  const [isLoading, setIsLoading] = useState(true)

  const maxItemsPerPage = config.items_per_list_page

  const buttonText = useMemo(() => {
    const orderedAtFilters = filters.filter((f) => f.key === 'due_at')
    if (orderedAtFilters.length > 0) {
      const startDate = new Date(
        `${orderedAtFilters.find((f) => f.op === 'gte')?.value}T00:00:00`
      )
      const endDate = new Date(
        `${orderedAtFilters.find((f) => f.op === 'lte')?.value}T23:59:59`
      )
      if (startDate && endDate) {
        return `${formatDate(startDate)} - ${formatDate(endDate)}`
      }
    }
    return 'dd/mm/aaaa - dd/mm/aaaa'
  }, [filters])

  useEffect(() => {
    const fetchInvoices = async () => {
      setIsLoading(true)

      const options = {
        attributes: [
          'id',
          'identifier',
          'currency',
          'amount_due',
          'due_at',
          'billing_period',
          'created_at',
          'updated_at'
        ],
        includes: {
          client: [
            'id',
            'account_name',
            'document',
            'document_type',
            'validated'
          ],
          statuses: ['*']
        },
        limit: maxItemsPerPage,
        filters: [
          ...filters,
          ...(clientId ? [{ key: 'client_id', op: 'eq', value: clientId }] : [])
        ],
        sort,
        search,
        page
      }

      try {
        const { count, data } = await list(options)
        setInvoices(data)
        setTotal(count)
        setPage(page)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        toast.error('Falha ao buscar faturas')
      }
    }

    fetchInvoices()
  }, [page, search, sort, filters, maxItemsPerPage])

  useEffect(() => {
    const newLastPage = Math.ceil(total / maxItemsPerPage)
    setLastPage(newLastPage)
  }, [maxItemsPerPage, total])

  const onSort = useCallback(
    (sortBy: string) => {
      if (!sortableValues.includes(sortBy)) return
      const sortValue = isSortedDesc ? -1 : 1
      setSort({ [sortBy]: sortValue })
      setSortedValue(sortBy)
      setIsSortedDesc(!isSortedDesc)
    },
    [isSortedDesc]
  )

  const removeDateFilters = useCallback(() => {
    setFilters(filters.filter((f) => f.key !== 'due_at'))
  }, [])

  const onPageChange = useCallback(
    (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setPage(newPage)
    },
    [lastPage]
  )

  const onFilter = useCallback(
    (key: string, value: any) => {
      const cleanFilters = removeFilter(filters, key)

      if (value === '') {
        if (cleanFilters.length > 0) {
          query.set('q', btoa(JSON.stringify(cleanFilters)))
        } else {
          query.delete('q')
        }

        replace({
          pathname: window.location.pathname,
          search: query.toString()
        })

        setFilters([...cleanFilters])
        return
      }

      if (key === 'statuses.status') {
        cleanFilters.push({ key, op: 'eq', value })
      }

      if (key === 'due_at') {
        cleanFilters.push(
          { key, op: 'gte', value: value[0] },
          { key, op: 'lte', value: value[1] }
        )
      }

      if (cleanFilters.length > 0) {
        query.set('q', btoa(JSON.stringify(cleanFilters)))
      } else {
        query.delete('q')
      }

      setFilters([...cleanFilters])

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })
    },
    [filters, replace, query]
  )

  const onSearch = useCallback(
    (term: string) => {
      if (!term || !term.length || term.length < config.min_search_length) {
        term = ''
      }

      if (term === '') {
        query.delete('search')
      } else {
        query.set('search', term)
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      setSearch(term)
    },
    [query, replace]
  )

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen)
  }

  return (
    <>
      {!clientId && (
        <>
          <PageHeader title="Faturas" action="Listagem">
            <SearchBar value={search} onChange={onSearch} />
          </PageHeader>
          <div className="w-full px-4 my-4 h-16 flex items-center justify-between overflow-visible">
            <div className="w-1/5">
              <SimpleCustomSelect
                label=""
                options={statusFilters}
                value={
                  filters?.find((filter) => filter.key === 'statuses.status')
                    ?.value ?? statusFilters[0].value
                }
                onChange={(value) => onFilter('statuses.status', value)}
              />
            </div>
            <div className="flex overflow-visible relative">
              <div className="w-full">
                <button
                  onClick={toggleDatePicker}
                  className="px-4 py-2 border rounded-lg bg-white text-black font-medium text-sm"
                >
                  {buttonText}
                </button>
                {isDatePickerOpen && (
                  <div className="absolute top-full right-0.5 w-[335px] max-h-[430px] overflow-y-auto z-50">
                    <DateRangePickerComponent
                      filters={filters}
                      onFilter={onFilter}
                      onClose={toggleDatePicker}
                      cleanRange={removeDateFilters}
                      columnName="due_at"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {isLoading ? (
        <LoadingCircle />
      ) : (
        <>
          <div className="flex flex-col animate-fade-in-down">
            <div className="-my-2 overflow-x-auto px-4">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          IDENTIFICADOR
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          ESTADO
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500 group cursor-pointer"
                          onClick={() => onSort('client.account_name')}
                        >
                          <div className={'flex justify-between items-center'}>
                            <div>CLIENTE</div>
                            {sortedValue === 'client.account_name' ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="group cursor-pointer px-4 py-3 text-left text-sm font-light text-gray-500"
                          onClick={() => onSort('amount_due')}
                        >
                          <div className={'flex justify-between items-center'}>
                            <div>VALOR</div>
                            {sortedValue === 'amount_due' ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          COMPETÊNCIA
                        </th>
                        <th
                          scope="col"
                          className="group cursor-pointer px-4 py-3 text-left text-sm font-light text-gray-500"
                          onClick={() => onSort('due_at')}
                        >
                          <div className={'flex justify-between items-center'}>
                            <div>VENCIMENTO</div>
                            {sortedValue === 'due_at' ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          AÇÕES
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {invoices.map((invoice, index) => (
                        <tr
                          key={invoice.id}
                          className={
                            index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                          }
                        >
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1 truncate">
                                {invoice.attributes.identifier}{' '}
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                            <div className="text-sm text-left font-semibold text-gray-900 truncate">
                              <Label
                                type="invoice_status"
                                label={
                                  translations['invoice_status'][
                                    invoice.relationships?.statuses[0]
                                      .attributes.status
                                  ]
                                }
                                value={
                                  invoice.relationships?.statuses[0].attributes
                                    .status
                                }
                              />
                            </div>
                            <div className="ml-2 text-xs text-left text-gray-500">
                              {' '}
                              {date(
                                invoice.relationships?.statuses[0].attributes
                                  .created_at as Date
                              )}{' '}
                            </div>
                          </td>
                          <td className="px-4 py-3 max-w-xxs whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1 truncate">
                                <div
                                  title={
                                    invoice.relationships?.client.attributes
                                      .account_name
                                  }
                                  className="flex items-center gap-x-1 text-xs text-left font-semibold text-gray-900 truncate"
                                >
                                  {
                                    invoice.relationships?.client.attributes
                                      .account_name
                                  }
                                  {invoice.relationships?.client.attributes
                                    .validated && (
                                    <BadgeCheckIcon className="h-5 w-5 text-emerald-600" />
                                  )}
                                </div>
                                <div className="text-xs text-left text-gray-500">
                                  {document(
                                    invoice.relationships?.client.attributes
                                      .document,
                                    invoice.relationships?.client.attributes
                                      .document_type
                                  )}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex">
                              <div className="flex-1">
                                <div className="text-xs text-left font-semibold text-gray-900">
                                  {' '}
                                  {`${currency(
                                    invoice.attributes.currency
                                  )} ${amount(
                                    invoice.attributes.amount_due,
                                    true,
                                    true
                                  )}`}{' '}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                            <div className="flex">
                              <div className="flex-1">
                                <div className="text-xs text-left text-gray-500">
                                  {formatYearMonth(
                                    invoice.attributes.billing_period!
                                  )}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                            <div className="flex">
                              <div className="flex-1">
                                <div className="text-xs text-left font-semibold text-gray-900">
                                  {invoice.attributes.due_at
                                    ?.toString()
                                    .substring(0, 10)
                                    .split('-')
                                    .reverse()
                                    .join('/')}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-xs text-left text-gray-500">
                            <div className="flex flex-row">
                              {
                                <Link to={`/invoices/${invoice.id}`}>
                                  <button
                                    type="button"
                                    className={
                                      'relative inline-flex items-center px-3 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500'
                                    }
                                  >
                                    <EyeIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </Link>
                              }
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="mx-4">
              <Pagination
                currentPage={page}
                pageSize={maxItemsPerPage}
                itemsOnPage={invoices.length}
                totalItems={total}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
