import { toast } from 'react-toastify'
import { useParams, useHistory } from 'react-router-dom'
import { useState, createContext, ReactNode, useCallback } from 'react'

import {
  createAddress as baseCreateAddress,
  createEnterprise as baseCreateEnterprise,
  createUser as baseCreateUser
} from '../services/create'
import {
  show,
  listAddress,
  listPlans,
  listSubscriptions as baseListSubscriptions,
  listUsers as baseListUsers
} from '../services/read'
import {
  edit as baseEditClient,
  editAddress as baseEditAddress,
  editEnterprise as baseEditEnterprise,
  editAccounts as baseEditAccounts,
  editUser as baseEditUser,
  sendEmail as baseSendEmail,
  generateUserPasswordToken,
  updateStatus,
  refresh
} from '../services/update'
import { destroyAddress, destroyUser } from '../services/delete'

import {
  Plan,
  Address,
  Client,
  ClientAddress,
  WizardSteps,
  AddressType,
  Identification,
  Order,
  User
} from 'models'
import { deepClone, isNil, omitBy } from 'utils'
import { Enterprise } from 'models/enterprise'
import {
  completeStep,
  isStepCompleted,
  isWizardCompleted
} from '../helpers/wizard'
import { UseEditClientProps } from '../hooks/useEditClient'
import { ReadResult } from 'interfaces/queryOptions'
import { buildAccounts } from '../helpers/utils'
import { ClientStatus } from 'models/clientStatusHistory'
import config from 'config'

type Props = {
  children: ReactNode
}

export const ClientEditContext = createContext<UseEditClientProps>(
  {} as UseEditClientProps
)

export function ClientEditProvider({ children }: Props) {
  const { clientId } = useParams() as unknown as { clientId: number }

  const history = useHistory()

  const [isSaving, setIsSaving] = useState(false)

  const [isLoadingClient, setIsLoadingClient] = useState(true)
  const [client, setClient] = useState<ReadResult<Client>>()
  const [clientStatus, setClientStatus] = useState<ClientStatus>()

  const [wizard, setWizard] = useState<string | undefined>()
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [identification, setIdentification] =
    useState<Partial<Identification>>()

  const [isLoadingAddress, setIsLoadingAddress] = useState(true)
  const [addresses, setAddresses] = useState<ReadResult<ClientAddress>[]>()

  const [isLoadingAccessPlans, setIsLoadingAccessPlans] = useState(false)
  const [accessPlan, setAccessPlan] = useState<ReadResult<Plan>>()

  const [isLoadingSubscription, setIsLoadingSubscription] = useState(true)
  const [subscriptions, setSubscriptions] = useState<ReadResult<Order>[]>()

  const [isLoadingEnterprise, setIsLoadingEnterprise] = useState(true)
  const [enterprise, setEnterprise] = useState<ReadResult<Enterprise>>()
  const [accounts, setAccounts] = useState<Record<string, boolean>>({
    enterprises: true,
    users: true
  })

  const [isLoadingUsers, setIsLoadingUsers] = useState(false)
  const [users, setUsers] = useState<ReadResult<User>[]>()

  const [language, setLanguage] = useState<string>('')

  const updateWizard = useCallback(
    async (step: WizardSteps) => {
      if (!client) return

      if (isWizardCompleted(client.attributes.wizard)) return

      const newWizard = completeStep(wizard, step)
      setWizard(newWizard)

      if (!isStepCompleted(client.attributes.wizard, step)) {
        baseEditClient(client.id, { wizard: newWizard })
        setClient({
          ...client,
          attributes: { ...client.attributes, wizard: newWizard }
        })
      }

      if (isWizardCompleted(newWizard)) {
        updateStatus(client.id, 'active').then((result) => {
          setClientStatus(result?.attributes.status)
        })
      }
    },
    [client, wizard]
  )

  const fetchClient = useCallback(async () => {
    if (!clientId) return

    setIsLoadingClient(true)

    try {
      const data = await show(clientId, {
        includes: {
          enterprise: ['*'],
          accounts: ['name'],
          statuses: ['*'],
          orders: ['*'],
          country: ['language', 'code']
        }
      })

      setLanguage(data.relationships?.country.attributes.language)
      setClient(data)
      setClientStatus(data.relationships?.statuses[0].attributes.status)
      setWizard(data.attributes.wizard)

      if (data.attributes.document_type === 'cnpj') {
        setIdentification({
          document: data.attributes.document,
          ...data.attributes.corporate_data
        })
      }

      setIsLoadingClient(false)
    } catch (error: any) {
      setIsLoadingClient(false)
      history.push('/clients')
      toast.error(
        error.suggestedMessage ?? 'Falha ao recuperar dados do cliente'
      )
    }
  }, [clientId, history])

  const editClient = useCallback(async () => {
    if (!client) return

    setIsSaving(true)
    try {
      const payload = deepClone(client.attributes)
      payload.created_at = undefined
      payload.updated_at = undefined
      payload.country_id = undefined
      payload.enterprise_id = undefined

      await baseEditClient(client.id, payload)

      toast.success('Cliente editado com sucesso')
      setIsSaving(false)
    } catch (error: any) {
      setIsSaving(false)
      toast.error(
        error.suggestedMessage ?? 'Houve uma falha ao tentar editar o cliente'
      )

      if (error.message.includes('email')) {
        return { email: 'Email inválido' }
      }
    }
  }, [client])

  const updateClientStatus = useCallback(
    async (newStatus: ClientStatus) => {
      if (!client) return

      if (newStatus === clientStatus) return

      try {
        const result = await updateStatus(client.id, newStatus)

        setClientStatus(result?.attributes.status)

        toast.success('Situação alterada com sucesso')
      } catch (error: any) {
        toast.error(
          error.suggestedMessage ?? 'Falha ao alterar situação do cliente'
        )
      }
    },
    [client, clientStatus]
  )

  const refreshCorporateData = useCallback(async () => {
    if (!clientId) return

    setIsRefreshing(true)
    try {
      const data = await refresh(clientId)

      if (
        data.attributes.document_type === 'cnpj' &&
        data.attributes.corporate_data
      ) {
        setIdentification({
          document: data.attributes.document,
          ...data.attributes.corporate_data
        })
      }

      setIsRefreshing(false)
    } catch (error: any) {
      setIsRefreshing(false)
      toast.error(
        error.suggestedMessage ?? 'Falha ao atualizar dados do cliente'
      )
    }
  }, [clientId])

  const listAddresses = useCallback(async () => {
    if (!clientId) return

    setIsLoadingAddress(true)

    try {
      const result = await listAddress({
        includes: {
          client: ['*'],
          address: [
            'coords',
            'formatted_address',
            'street_number',
            'street',
            'complement',
            'city',
            'region',
            'country',
            'zip_code',
            'description',
            'type'
          ]
        },
        filters: [{ key: 'client_id', op: 'eq', value: clientId }]
      })

      if (result.data.length) {
        setAddresses(result.data)
        await updateWizard('address')
      }
      setIsLoadingAddress(false)
    } catch (error: any) {
      console.error(error)
      setIsLoadingAddress(false)
      toast.error(error.suggestedMessage ?? 'Falha ao recuperar endereços')
    }
  }, [clientId, updateWizard])

  const createAddress = useCallback(
    async (attributes: Address, type: AddressType) => {
      if (!clientId) return

      setIsSaving(true)

      try {
        const result = await baseCreateAddress(clientId, attributes, type)

        if (!addresses) {
          setAddresses([result])
          await updateWizard('address')
        }

        delete result.relationships?.address.attributes.model

        if (addresses) {
          addresses.push(result)
          setAddresses([...addresses])
        }

        setIsSaving(false)
        return result
      } catch (error: any) {
        setIsSaving(false)
        console.error(error)
        toast.error(error.suggestedMessage ?? 'Erro ao criar endereço')
      }
    },
    [addresses, clientId, updateWizard]
  )

  const editAddress = useCallback(
    async (index: number, attributes: Partial<Address>, type: AddressType) => {
      if (!clientId || !addresses) return

      setIsSaving(true)
      if (typeof attributes.coords === 'string') {
        attributes.coords = undefined
      }

      attributes.created_at = undefined
      attributes.updated_at = undefined

      const payload: Record<string, any> = {
        attributes: {
          ...omitBy(attributes, isNil),
          type
        }
      }

      if (!payload.attributes.complement) payload.attributes.complement = ' '

      try {
        const result = await baseEditAddress(addresses[index].id, payload)
        delete result.relationships?.address.attributes.model

        addresses[index] = result
        setAddresses([...addresses])

        setIsSaving(false)
        toast.success('Endereço atualizado com sucesso')
      } catch (error: any) {
        setIsSaving(false)
        console.error(error)
        toast.error(error.suggestedMessage ?? 'Erro ao atualizar endereço')
      }
    },
    [addresses, clientId]
  )

  const deleteAddress = useCallback(
    async (id: number) => {
      if (!addresses) return

      try {
        await destroyAddress(addresses[id].id)

        addresses.splice(id, 1)
        setAddresses([...addresses])
      } catch (error: any) {
        console.error(error)
        toast.error(error.suggestedMessage ?? 'Falha ao remover endereços')
      }
    },
    [addresses]
  )

  const listAccessPlans = useCallback(
    async (options: any) => {
      if (!clientId) return

      setIsLoadingAccessPlans(true)
      try {
        const result = await listPlans(options)
        setIsLoadingAccessPlans(false)
        return result
      } catch (error: any) {
        console.error(error)
        setIsLoadingAccessPlans(false)
        toast.error(error.suggestedMessage ?? 'Falha ao buscar planos')
      }
    },
    [clientId]
  )

  const listSubscriptions = useCallback(async () => {
    if (!clientId || !client) return

    setIsLoadingSubscription(true)

    try {
      const result = await baseListSubscriptions({
        filters: [{ key: 'client_id', op: 'eq', value: clientId }]
      })

      if (result.data.length) {
        setSubscriptions(result.data)
        await updateWizard('subscription')
      }
      setIsLoadingSubscription(false)
    } catch (error: any) {
      setIsLoadingSubscription(false)
      toast.error(error.suggestedMessage ?? 'Falha ao buscar assinaturas')
    }
  }, [client, clientId, updateWizard])

  const fetchEnterprise = useCallback(async () => {
    if (!clientId || !client) return

    setIsLoadingEnterprise(true)
    try {
      if (client.relationships?.enterprise) {
        const enterprise = client.relationships?.enterprise
        setEnterprise(enterprise)
        setAccounts(buildAccounts(client.relationships?.accounts))

        await updateWizard('enterprise')
      }

      setIsLoadingEnterprise(false)
    } catch (error: any) {
      setIsLoadingEnterprise(false)
      toast.error(
        error.suggestedMessage ?? 'Falha ao recuperar dados da empresa'
      )
    }
  }, [clientId, client, updateWizard])

  const createEnterprise = useCallback(
    async (attributes: { support: boolean; accounts: string[] }) => {
      if (!clientId || !client) return

      setIsSaving(true)
      try {
        const result = await baseCreateEnterprise(clientId, attributes)

        setEnterprise(result)
        setClient({
          ...client,
          attributes: { ...client.attributes, enterprise_id: result.id },
          relationships: { ...client.relationships, enterprise: { ...result } }
        })
        await updateWizard('enterprise')

        setIsSaving(false)
        toast.success('Empresa criada e vinculada com sucesso')
      } catch (error: any) {
        setIsSaving(false)
        toast.error(error.suggestedMessage ?? 'Erro ao criar empresa')
      }
    },
    [client, clientId, updateWizard]
  )

  const editEnterprise = useCallback(
    async (enterpriseId: string, attributes: any) => {
      if (!clientId) return

      setIsSaving(true)
      try {
        const result = await baseEditEnterprise(enterpriseId, attributes)
        setEnterprise(result)
        setIsSaving(false)
        toast.success('Empresa atualizada com sucesso')
      } catch (error: any) {
        setIsSaving(false)
        toast.error(error.suggestedMessage ?? 'Erro ao atualizar empresa')
      }
    },
    [clientId]
  )

  const editAccounts = useCallback(async () => {
    if (!clientId || !client || !enterprise) return

    setIsSaving(true)

    try {
      await baseEditAccounts(enterprise.id, accounts)

      setIsSaving(false)
      setAccounts({ ...accounts })
      toast.success('Conta atualizada com sucesso')
    } catch (error: any) {
      setIsSaving(false)
      toast.error(error.suggestedMessage ?? 'Erro ao atualizar conta')
    }
  }, [accounts, client, enterprise, clientId])

  const listUsers = useCallback(async () => {
    if (!clientId || !enterprise || !client?.relationships?.enterprise?.id) {
      setIsLoadingUsers(false)
      return
    }

    setIsLoadingUsers(true)
    try {
      const result = await baseListUsers({
        attributes: ['name', 'email', 'username', 'user_type', 'phone1'],
        includes: { roles: ['id'] },
        filters: [
          { key: 'user_type', op: 'eq', value: 'ADMIN' },
          { key: 'enterprise_id', op: 'eq', value: enterprise.id }
        ]
      })

      result.data = transformData(result.data)

      if (result.data.length) {
        setUsers(result.data)
        await updateWizard('user')
      }

      setIsLoadingUsers(false)
    } catch (error: any) {
      setIsLoadingUsers(false)
      toast.error(error.suggestedMessage ?? 'Falha ao buscar usuários')
    }
  }, [clientId, enterprise, client, updateWizard])

  const createUser = useCallback(
    async (attributes: Partial<User>) => {
      if (!clientId || !client || !enterprise) return

      setIsSaving(true)
      try {
        const result = await baseCreateUser(
          enterprise.id,
          config.account_role_id!,
          attributes
        )

        if (!users) {
          setUsers([result])
          await updateWizard('user')
        } else {
          users.push(result)
          setUsers([...users])
        }

        setIsSaving(false)
      } catch (error: any) {
        setIsSaving(false)
        if (error.message.includes('email')) {
          toast.error(error.suggestedMessage ?? 'Erro ao validar email')
        } else {
          toast.error(error.suggestedMessage ?? 'Erro ao criar administrador')
        }
      }
    },
    [clientId, client, enterprise, users, updateWizard]
  )

  const editUser = useCallback(
    async (index: number, user: ReadResult<User>) => {
      if (!users) return

      setIsSaving(true)
      try {
        const result = await baseEditUser(user.id.toString(), user.attributes)
        users[index] = transformData([result])[0]
        setUsers([...users])

        setIsSaving(false)
        toast.success('Usuário editado com sucesso')
      } catch (error: any) {
        setIsSaving(false)
        if (error.message.includes('email')) {
          toast.error(error.suggestedMessage ?? 'Erro ao validar email')
        } else {
          toast.error(error.suggestedMessage ?? 'Erro ao editar administrador')
        }
      }
    },
    [users]
  )

  const deleteUser = useCallback(
    async (id: number) => {
      if (!users) return

      try {
        await destroyUser(users[id].id.toString())

        users.splice(id, 1)
        setUsers([...users])

        setIsLoadingUsers(false)
      } catch (error: any) {
        setIsLoadingUsers(false)
        toast.error(error.suggestedMessage ?? 'Falha ao remover usuário')
      }
    },
    [users]
  )

  const sendEmail = useCallback(
    async (index: number) => {
      if (!users) return

      const user = users[index]

      setIsSaving(true)
      try {
        await generateUserPasswordToken({
          attributes: {
            username: user.attributes.username
          }
        })

        await baseSendEmail(user.id)
        setIsSaving(false)
        toast.success('Email de boas vindas enviado com sucesso')
      } catch (error) {
        toast.error('Falha ao enviar email de boas vindas')
        setIsSaving(false)
      }
    },
    [users]
  )

  function transformData(originalData: any) {
    return originalData.map((item: any) => {
      return {
        type: item.type,
        id: item.id,
        attributes: {
          name: item.attributes.attributes.name,
          email: item.attributes.attributes.email,
          username: item.attributes.attributes.username,
          user_type: item.attributes.attributes.user_type,
          phone1: item.attributes.attributes.phone1
        }
      }
    })
  }

  return (
    <ClientEditContext.Provider
      value={{
        isSaving,

        isLoadingClient,
        wizard,
        setWizard,
        client,
        setClient,
        fetchClient,
        editClient,
        identification,
        clientStatus,
        setClientStatus,
        updateClientStatus,
        isRefreshing,
        refreshCorporateData,

        isLoadingAddress,
        addresses,
        setAddresses,
        listAddresses,
        createAddress,
        editAddress,
        deleteAddress,

        isLoadingAccessPlans,
        accessPlan,
        setAccessPlan,
        listAccessPlans,

        isLoadingSubscription,
        subscriptions,
        setSubscriptions,
        listSubscriptions,

        isLoadingEnterprise,
        enterprise,
        setEnterprise,
        fetchEnterprise,
        createEnterprise,
        editEnterprise,
        accounts,
        setAccounts,
        editAccounts,

        isLoadingUsers,
        users,
        setUsers,
        listUsers,
        createUser,
        editUser,
        deleteUser,
        sendEmail,

        language
      }}
    >
      {children}
    </ClientEditContext.Provider>
  )
}
