export function getAccessPlanMaxInstallmentsNumber(
  planDuration: number,
  planInterval: string
): number {
  const maxInstallmentsNumber = (() => {
    switch (planInterval) {
      case 'day':
        return planDuration / 30 // considering 1 month = 30 days
      case 'week':
        return planDuration / 4 // considering 1 month = 4 weeks
      case 'month':
        return planDuration
      case 'year':
        return planDuration * 12
      default:
        throw new Error('Invalid plan interval')
    }
  })()

  return maxInstallmentsNumber
}
