export type Translations = Record<string, Record<string, string>>

export const translations: Translations = {
  type: {
    software: 'Software',
    connectivity: 'Conectividade',
    hardware: 'Hardware',
    other: 'Outros'
  },
  subtype: {
    Claro: 'Claro',
    Vivo: 'Vivo',
    Tim: 'Tim',
    Algar: 'Algar',
    Arqia: 'Arqia',
    softruck_s4g: 'Softruck S4G',
    suntech_st310_u: 'Suntech 310u',
    suntech_st310_uc2: 'Suntech 310uc2',
    suntech_300hd: 'Suntech 300HD',
    cr_4000_a: 'CR4000 A',
    mt_2000: 'MT 2000',
    nt20: 'NT20',
    nt40: 'NT40',
    globalstar_smartone_c: 'Globalstar Smartone C',
    y202: 'Y202',
    j16: 'J16',
    y18: 'Y18',
    j14: 'J14',
    queclink_gv50: 'Queclink GV50',
    'e4-mini': 'E4 mini'
  },
  nature: {
    service: 'Serviço',
    good: 'Bem'
  },
  interval: {
    day: 'Diário',
    week: 'Semanal',
    month: 'Mensal',
    year: 'Anual'
  },
  billing_type: {
    prepaid: 'Pré-pago',
    postpaid: 'Pós-pago'
  }
}
