import { Invoice, InvoiceStatus } from 'models/invoice'
import { useCallback, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { listItemsByInvoiceId, show } from '../services/read'
import { InvoiceItem } from 'models/invoiceItem'
import { toast } from 'react-toastify'
import { LoadingCircle } from 'components/Loading'
import { NotFoundPage } from 'components/NotFoundPage'
import { ReviewTable } from '../components/ReviewTable'
import { Client } from 'models'
import { Label } from 'components/Label'
import { translations } from '../translations'
import { amount, currency } from 'utils'
import { ExternalLinkIcon } from '@heroicons/react/solid'
import { CSVLink } from 'react-csv'
import { SortDownIcon } from 'components/Table/SortDownIcon'
import { SorUptIcon } from 'components/Table/SortUpIcon'
import { SortIcon } from 'components/Table/SortIcon'

type InvoiceItemWithOrderData = InvoiceItem & {
  order_token?: string
  plan_type?: string
  plan_name?: string
  plan_id?: string
}

interface DynamicCSVProps {
  headers: { label: string; key: string }[]
  data: Record<string, any>[]
  filename: string
}

export function Show() {
  const { invoiceId } = useParams() as unknown as { invoiceId: number }

  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)

  const [invoice, setInvoice] = useState<Invoice>()
  const [items, setItems] = useState<InvoiceItemWithOrderData[]>([])

  const [client, setClient] = useState<Partial<Client>>()

  const [invoiceCurrentStatus, setInvoiceCurrentStatus] =
    useState<InvoiceStatus>()

  const [sort, setSort] = useState<Record<string, number>>()
  const sortableValues = [
    'type',
    'order.token',
    'plan.name',
    'quantity',
    'unit_amount',
    'amount'
  ]
  const [sortedValue, setSortedValue] = useState<string>()
  const [isSortedDesc, setIsSortedDesc] = useState(true)

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const invoice = await show(invoiceId, {
          includes: {
            client: ['id', 'account_name', 'document', 'document_type'],
            statuses: ['*'],
            items: [
              'order_id',
              'currency',
              'amount',
              'unit_amount',
              'quantity',
              'type',
              'description',
              'billing_period'
            ]
          }
        })

        if (invoice.relationships?.items) {
          const invoiceItemsData = await listItemsByInvoiceId(
            invoice.id as number,
            {
              includes: { order: ['token'], plan: ['type', 'name'] },
              sort
            }
          )

          const mappedItems: InvoiceItemWithOrderData[] =
            invoiceItemsData.data.map((item) => {
              const attributes = item.attributes as any

              return {
                id: item.id,
                invoice_id: item.relationships?.invoice.id,
                client_id: item.relationships?.client.id,
                order_id: item.relationships?.order.id,
                order_token: item.relationships?.order.attributes.token,
                plan_type: item.relationships?.plan.attributes.type,
                plan_name: item.relationships?.plan.attributes.name,
                plan_id: item.relationships?.plan.id,
                ...attributes
              }
            })

          setItems(mappedItems)
        }

        setInvoice(invoice as Invoice)

        setInvoiceCurrentStatus(
          invoice.relationships?.statuses[0].attributes.status
        )

        setClient(invoice.relationships?.client)

        setIsLoading(false)
      } catch (err: any) {
        history.push('/invoices')
        toast.error(err.suggestedMessage ?? 'Falha ao buscar a fatura')
      }
    }

    fetchInvoice()
  }, [sort])

  const onSort = useCallback(
    (sortBy: string) => {
      if (!sortableValues.includes(sortBy)) return
      const sortValue = isSortedDesc ? -1 : 1
      setSort({ [sortBy]: sortValue })
      setSortedValue(sortBy)
      setIsSortedDesc(!isSortedDesc)
    },
    [isSortedDesc]
  )

  function generateCsvData(
    invoice: Invoice,
    items: InvoiceItemWithOrderData[]
  ): DynamicCSVProps {
    return {
      filename: invoice.identifier,
      headers: [
        { label: 'Plano/Acesso', key: 'plan' },
        { label: 'Pedido/Assinatura', key: 'order' },
        { label: 'Tipo', key: 'type' },
        { label: 'Descrição', key: 'description' },
        { label: 'Quantidade', key: 'quantity' },
        { label: 'Valor unitário', key: 'unit_amount' },
        { label: 'Valor total', key: 'amount' }
      ],
      data: items.map((i) => ({
        plan: i.plan_name,
        order: i.order_token,
        type: translations['invoice_item_type'][i.type],
        description: i.description ?? '-',
        quantity: i.quantity,
        unit_amount: `${currency(i.currency)} ${amount(
          i.unit_amount,
          true,
          true
        )}`,
        amount: `${currency(i.currency)} ${amount(i.amount, true, true)}`
      }))
    }
  }

  return isLoading ? (
    <LoadingCircle />
  ) : !invoice ? (
    <NotFoundPage />
  ) : (
    <div className="overflow-hidden px-4">
      <div className="flex flex-row m-4 animate-fade-in-down gap-x-4">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Fatura: {invoice.identifier}{' '}
        </h3>
        <Label
          type="invoice_status"
          label={
            translations['invoice_status'][
              invoiceCurrentStatus as InvoiceStatus
            ]
          }
          value={invoiceCurrentStatus}
        />
      </div>

      <ReviewTable invoice={invoice} client={client} />

      {items.length > 0 && (
        <>
          <div className="flex flex-col animate-fade-in-down mt-4">
            <div className="-my-2 overflow-x-auto px-4">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="flex justify-between items-center mb-2">
                  <h4 className="text-md leading-6 font-small">
                    Itens faturados:
                  </h4>
                  <button
                    type="button"
                    className="inline-flex items-center px-1.5 border border-gray-300 shadow-sm text-md font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                  >
                    <CSVLink {...generateCsvData(invoice, items)}>
                      Baixar planilha
                    </CSVLink>
                  </button>
                </div>
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500 group cursor-pointer"
                          onClick={() => onSort('plan.name')}
                        >
                          <div className="flex justify-between items-center">
                            <div>PLANO / ACESSO</div>
                            {sortedValue === 'plan.name' ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500 group cursor-pointer"
                          onClick={() => onSort('order.token')}
                        >
                          <div className="flex justify-between items-center">
                            <div>PEDIDO / ASSINATURA</div>
                            {sortedValue === 'order.token' ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500 group cursor-pointer"
                          onClick={() => onSort('type')}
                        >
                          <div className="flex justify-between items-center">
                            <div>TIPO</div>
                            {sortedValue === 'type' ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          DESCRIÇÃO
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500 group cursor-pointer"
                          onClick={() => onSort('quantity')}
                        >
                          <div className="flex justify-between items-center">
                            <div>QUANTIDADE</div>
                            {sortedValue === 'quantity' ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500 group cursor-pointer"
                          onClick={() => onSort('unit_amount')}
                        >
                          <div className="flex justify-between items-center">
                            <div>VALOR UNITÁRIO</div>
                            {sortedValue === 'unit_amount' ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500 group cursor-pointer"
                          onClick={() => onSort('amount')}
                        >
                          <div className="flex justify-between items-center">
                            <div>VALOR TOTAL</div>
                            {sortedValue === 'amount' ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {items.map((item, index) => (
                        <tr
                          key={item.id}
                          className={
                            index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                          }
                        >
                          <td className="px-4 py-3 text-left text-sm text-gray-900 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1 truncate">
                                {item.order_id ? (
                                  <Link
                                    to={
                                      item.plan_type === 'software'
                                        ? `/access-plans/${item.plan_id}/view/general`
                                        : `/plans/${item.plan_id}/view/general`
                                    }
                                    className="text-gray-900 hover:underline"
                                  >
                                    <div className="flex items-center">
                                      {item.plan_name}
                                      <ExternalLinkIcon
                                        className="h-4 w-4 text-gray-900 ml-2"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </Link>
                                ) : (
                                  '-'
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm text-gray-900 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1 truncate">
                                {item.order_id ? (
                                  <Link
                                    to={
                                      item.plan_type === 'software'
                                        ? `/subscriptions/${item.order_id}/view`
                                        : `/orders/${item.order_id}/${
                                            item.plan_type === 'connectivity'
                                              ? 'chips'
                                              : 'devices'
                                          }`
                                    }
                                    className="text-gray-900 hover:underline"
                                  >
                                    <div className="flex items-center">
                                      {item.order_token}
                                      <ExternalLinkIcon
                                        className="h-4 w-4 text-gray-900 ml-2"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </Link>
                                ) : (
                                  '-'
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm text-gray-900 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1 truncate">
                                {translations['invoice_item_type'][item.type]}
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm text-gray-900 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1 truncate">
                                {item.description ?? '-'}
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm text-gray-900 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1 truncate">
                                {item.quantity}
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm text-gray-900 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1 truncate">
                                {`${currency(item.currency)} ${amount(
                                  item.unit_amount,
                                  true,
                                  true
                                )}`}
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm text-gray-900 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1 truncate">
                                {`${currency(item.currency)} ${amount(
                                  item.amount,
                                  true,
                                  true
                                )}`}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
