import { useCallback, useEffect, useState } from 'react'
import { ExclamationIcon } from '@heroicons/react/outline'

import { Modal } from 'components/Modal'
import { ClientChip } from 'models/clientChips'
import { OrderConnectivityStatus } from 'models'
import { Close } from 'components/Buttons/Close'
import { Button } from 'components/Buttons/Basic'
import { ReadResult } from 'interfaces/queryOptions'
import { SimpleCustomSelect } from 'components/FormInputs'
import { useViewClient } from 'modules/Clients/hooks/useViewClient'

const returned = { value: 'returned', label: 'Devolvido' }
const canceled = { value: 'canceled', label: 'Cancelado' }
const suspended = { value: 'suspended', label: 'Suspenso' }
const ordered = { value: 'ordered', label: 'Ativo' }

interface Props {
  index: number | undefined
  isOpen: boolean
  isItemSuspended: boolean
  setOpen: (isOpen: boolean) => void
}

export function UpdateChipStatus({
  index,
  isOpen,
  isItemSuspended,
  setOpen
}: Props) {
  const { chips, updateChipStatus } = useViewClient()

  const [chip, setChip] = useState<ReadResult<ClientChip>>()

  const [newChipStatus, setNewChipStatus] = useState<OrderConnectivityStatus>()

  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (index === undefined || !chips) return

    const chip = chips.data[index]

    if (chip) {
      setChip(chip)
    }
  }, [index, chips])

  const onClose = useCallback(() => {
    setOpen(false)
    setNewChipStatus(undefined)
  }, [setOpen])

  const onSave = useCallback(async () => {
    if (index === undefined || !chip) return

    if (!newChipStatus) return

    setIsSaving(true)
    const payload = {
      item_id: chip.id,
      status: newChipStatus
    }

    await updateChipStatus(index, payload)
    setIsSaving(false)
    onClose()
  }, [chip, index, newChipStatus, onClose, updateChipStatus])

  return (
    <Modal open={isOpen} title="Atualização de estado" onClose={onClose}>
      <div className="grid grid-cols-12 gap-4 mt-4 animate-fade-in-down">
        <div className="col-span-12">
          <SimpleCustomSelect
            label="Novo estado"
            options={
              isItemSuspended ? [ordered] : [returned, canceled, suspended]
            }
            value={newChipStatus}
            onChange={setNewChipStatus}
          />
        </div>
      </div>

      <div className="col-span-12">
        <div className="mt-4 rounded-md bg-yellow-50 p-4 animate-fade-in-down">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">Atenção</h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  Ao alterar o estado de um chip, ele será deletado e o cliente
                  não poderá mais visualizá-lo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8 flex space-x-4 justify-end animate-fade-in-down">
        <Close onClick={onClose} />

        <Button
          type="button"
          onClick={onSave}
          disabled={isSaving}
          label="Salvar"
        />
      </div>
    </Modal>
  )
}
