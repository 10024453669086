import { Translations } from 'types'

export const translations: Translations = {
  invoice_status: {
    draft: 'Rascunho',
    open: 'Aberta',
    paid: 'Paga',
    uncollectible: 'Inadimplente',
    canceled: 'Cancelada'
  },
  invoice_item_type: {
    standard: 'Padrão',
    fine: 'Multa',
    manual_addition: 'Adição manual',
    award_sanction: 'Sanção premiativa',
    prorata: 'Pro rata',
    coupon: 'Cupom',
    complement: 'Complemento',
    reversal: 'Estorno',
    award_sanction_loss: 'Perda de sanção premiativa',
    coupon_loss: 'Perda de cupom',
    setup: 'Adesão',
    hardware: 'Equipamentos'
  }
}
