import config from 'config'
import { useURLParams } from 'hooks/useURLParams'
import { QueryFilter, ReadResult } from 'interfaces/queryOptions'
import {
  ChipsRequirement,
  ChipsRequirementStatuses,
  ChipsRequirementTypes
} from 'models/chips_requirement'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { list, update } from '../services/api'
import { Pagination } from 'components/Pagination'
import { PageHeader } from 'components/PageHeader'
import { SearchBar } from 'components/Search'
import { SimpleCustomSelect } from 'components/FormInputs'
import { LoadingCircle } from 'components/Loading'
import { date, phone, time } from 'utils'
import { Label } from 'components/Label'
import { translations } from 'modules/Orders/translations'
import { ProtectedSection } from 'components/ProtectedSection'
import { Role } from 'models'
import { useConfirmationDialog } from 'components/ConfirmationDialog'
import { RequirementTabs } from 'modules/Items/components/RequirementTabs'
import { CheckBox } from 'modules/Clients/components/Tabs/Edit/Enterprise/components/CheckBox'
import { formatDate } from 'utils/datetime'
import { DateRangePickerComponent } from 'components/Date/DateRangePicker'

const typeFilters = [
  { value: '', label: 'Selecione um tipo' },
  { value: ChipsRequirementTypes.CANCELATION, label: 'Cancelamento' },
  { value: ChipsRequirementTypes.SUSPENDED, label: 'Suspensão' },
  { value: ChipsRequirementTypes.ACTIVATION, label: 'Ativação' }
]

const executeOrRefuseRequirements = [
  { value: ChipsRequirementStatuses.EXECUTED, label: 'Executar' },
  { value: ChipsRequirementStatuses.REFUSED, label: 'Recusar' }
]

const processRequirements = [
  {
    value: ChipsRequirementStatuses.IN_PROGRESS,
    label: 'Iniciar processamento'
  }
]

export function List() {
  const { replace } = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(() => {
    const params = {
      filters: [],
      search: ''
    }

    const search = query.get('search')
    const filters = query.get('q')

    if (search) params.search = search
    if (filters) params.filters = JSON.parse(atob(filters))

    return params
  }, [query])

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [search, setSearch] = useState(queryParams.search)
  const [filters, setFilters] = useState<QueryFilter[]>([
    { key: 'status', op: 'eq', value: 'open' }
  ])

  const [currentTab, setCurrentTab] = useState(ChipsRequirementStatuses.OPEN)

  const handleTabChange = (value: string) => {
    setCurrentTab(value as ChipsRequirementStatuses)
    onFilter('status', value)
  }

  const [requirementsSelected, setRequirementsSelected] = useState<number[]>([])

  const handleCheckBoxChange = (id: number) => {
    setRequirementsSelected((prev) =>
      prev.includes(id)
        ? prev.filter((requirementId) => requirementId !== id)
        : [...prev, id]
    )
  }

  const handleAllCheckBoxChange = () => {
    const allIds = Object.values(requirements).map(
      (requirement) => requirement.id as number
    )

    setRequirementsSelected((prev) =>
      allIds.every((id) => prev.includes(id)) ? [] : allIds
    )
  }

  const [total, setTotal] = useState(0)
  const [requirements, setRequirements] = useState<
    ReadResult<ChipsRequirement>[]
  >([])

  const [requirementsStatus, setRequirementsStatus] =
    useState<ChipsRequirementStatuses>(ChipsRequirementStatuses.OPEN)

  const [isLoading, setIsLoading] = useState(true)

  const maxItemsPerPage = config.items_per_list_page

  const buttonText = useMemo(() => {
    const orderedAtFilters = filters.filter((f) => f.key === 'created_at')
    if (orderedAtFilters.length > 0) {
      const startDate = new Date(
        `${orderedAtFilters.find((f) => f.op === 'gte')?.value}T00:00:00`
      )
      const endDate = new Date(
        `${orderedAtFilters.find((f) => f.op === 'lte')?.value}T00:00:00`
      )
      if (startDate && endDate) {
        return `${formatDate(startDate)} - ${formatDate(endDate)}`
      }
    }
    return 'dd/mm/aaaa - dd/mm/aaaa'
  }, [filters])

  useEffect(() => {
    const fetchRequirements = async () => {
      setIsLoading(true)

      const options = {
        attributes: ['*'],
        includes: {
          chip: ['id', 'serial', 'number', 'batch', 'status']
        },
        limit: maxItemsPerPage,
        filters,
        search,
        page
      }

      try {
        const { count, data } = await list(options)
        setRequirements(data)
        setTotal(count)
        setPage(page)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        toast.error('Falha ao buscar solicitações')
      }
    }

    fetchRequirements()
  }, [page, search, filters, maxItemsPerPage])

  useEffect(() => {
    const newLastPage = Math.ceil(total / maxItemsPerPage)
    setLastPage(newLastPage)
  }, [maxItemsPerPage, total])

  const [showConfirmationDialog] = useConfirmationDialog()

  const onUpdateRequirements = useCallback(
    async (status: ChipsRequirementStatuses) => {
      try {
        await update(requirementsSelected, status)

        toast.success('Ação concluída com sucesso!')

        setRequirementsSelected([])

        handleTabChange(status as ChipsRequirementStatuses)
      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Não foi possível executar a ação!')
      }
    },
    [requirementsSelected]
  )

  const onPageChange = useCallback(
    (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setPage(newPage)
    },
    [lastPage]
  )

  const onFilter = useCallback(
    (key: string, value: any) => {
      setRequirementsSelected([])

      const cleanFilters = filters.filter((f) => f.key !== key)

      if (key === 'status') {
        setRequirementsStatus(value as ChipsRequirementStatuses)
      }

      if (value === '') {
        if (cleanFilters.length > 0) {
          query.set('q', btoa(JSON.stringify(cleanFilters)))
        } else {
          query.delete('q')
        }

        replace({
          pathname: window.location.pathname,
          search: query.toString()
        })

        setFilters([...cleanFilters])
        return
      }

      if (key === 'status' || key === 'type') {
        cleanFilters.push({ key, op: 'eq', value })
      }

      if (key === 'created_at') {
        cleanFilters.push(
          { key, op: 'gte', value: value[0] },
          { key, op: 'lte', value: value[1] }
        )
      }

      if (cleanFilters.length > 0) {
        query.set('q', btoa(JSON.stringify(cleanFilters)))
      } else {
        query.delete('q')
      }

      setFilters([...cleanFilters])

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })
    },
    [filters, replace, query]
  )

  const onSearch = useCallback(
    (term: string) => {
      if (!term || !term.length || term.length < config.min_search_length) {
        term = ''
      }

      if (term === '') {
        query.delete('search')
      } else {
        query.set('search', term)
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      setSearch(term)
    },
    [query, replace]
  )

  const removeDateFilters = useCallback(() => {
    setFilters(filters.filter((f) => f.key !== 'created_at'))
  }, [])

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen)
  }

  return (
    <>
      <PageHeader title="Solicitações" action="Listagem">
        <SearchBar value={search} onChange={onSearch} />
      </PageHeader>

      <div className="w-full px-4 my-4 h-16 flex items-center justify-between overflow-visible">
        <div className="w-1/5">
          <SimpleCustomSelect
            label=""
            options={typeFilters}
            value={
              filters?.find((filter) => filter.key === 'type')?.value ??
              typeFilters[0].value
            }
            onChange={(value) => onFilter('type', value)}
          />
        </div>
        <div className="flex overflow-visible relative">
          <div className="w-full">
            <button
              onClick={toggleDatePicker}
              className="px-4 py-2 border rounded-lg bg-white text-black font-medium text-sm"
            >
              {buttonText}
            </button>
            {isDatePickerOpen && (
              <div className="absolute top-full right-0.5 w-[335px] max-h-[430px] overflow-y-auto z-50">
                <DateRangePickerComponent
                  filters={filters}
                  onFilter={onFilter}
                  onClose={toggleDatePicker}
                  cleanRange={removeDateFilters}
                  columnName="created_at"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="m-4 w-2/3">
        <RequirementTabs
          onChange={(value: string) => onFilter('status', value)}
          currentTab={currentTab}
        />
      </div>

      {isLoading ? (
        <LoadingCircle />
      ) : (
        <>
          <div className="flex flex-col animate-fade-in-down">
            <div className="-my-2 overflow-visible px-4">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="shadow border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      {requirementsSelected.length > 0 ? (
                        <tr>
                          <th
                            colSpan={
                              [
                                ChipsRequirementStatuses.OPEN,
                                ChipsRequirementStatuses.IN_PROGRESS
                              ].includes(requirementsStatus)
                                ? 6
                                : 5
                            }
                          >
                            <div className="flex items-center space-x-4 pl-3">
                              <div className="ml-2">
                                <CheckBox
                                  name="selectAllRequirements"
                                  label=""
                                  checked={
                                    requirements.length > 0 &&
                                    Object.values(requirements).every(
                                      (requirement) =>
                                        requirementsSelected.includes(
                                          requirement.id as number
                                        )
                                    )
                                  }
                                  onChange={handleAllCheckBoxChange}
                                />
                              </div>
                              <ProtectedSection
                                roles={[
                                  Role.MANAGER,
                                  Role.FINANCE,
                                  Role.LOGISTICS
                                ]}
                              >
                                <div className="relative w-full font-normal text-md pr-3">
                                  {
                                    <SimpleCustomSelect
                                      options={[
                                        {
                                          value: undefined,
                                          label:
                                            'Escolher ação para os itens selecionados'
                                        },
                                        ...(requirementsStatus ===
                                        ChipsRequirementStatuses.IN_PROGRESS
                                          ? executeOrRefuseRequirements
                                          : processRequirements)
                                      ]}
                                      onChange={(value) => {
                                        showConfirmationDialog({
                                          title:
                                            requirementsStatus ===
                                            ChipsRequirementStatuses.IN_PROGRESS
                                              ? 'Concluir solicitações'
                                              : 'Processar solicitações',
                                          message: `Tem certeza que deseja ${
                                            requirementsStatus !==
                                            ChipsRequirementStatuses.IN_PROGRESS
                                              ? 'processar'
                                              : value ===
                                                ChipsRequirementStatuses.EXECUTED
                                              ? 'executar'
                                              : 'recusar'
                                          } as solicitações selecionadas?`,
                                          onConfirm: () =>
                                            onUpdateRequirements(value)
                                        })
                                      }}
                                      buttonClassNameExtras="h-9"
                                      dropdownClassName="absolute z-50 mt-1 max-h-60 overflow-auto bg-white shadow-lg border border-gray-200"
                                    />
                                  }
                                </div>
                              </ProtectedSection>
                            </div>
                          </th>
                        </tr>
                      ) : (
                        <tr>
                          {[
                            ChipsRequirementStatuses.OPEN,
                            ChipsRequirementStatuses.IN_PROGRESS
                          ].includes(requirementsStatus) && (
                            <ProtectedSection
                              roles={[
                                Role.MANAGER,
                                Role.FINANCE,
                                Role.LOGISTICS
                              ]}
                            >
                              <th
                                scope="col"
                                className="pb-3 pr-3 pt-3 text-left text-xs font-medium text-gray-500 uppercase"
                              >
                                <div className="ml-5">
                                  <CheckBox
                                    name="selectAllRequirements"
                                    label=""
                                    checked={
                                      requirements.length > 0 &&
                                      Object.values(requirements).every(
                                        (requirement) =>
                                          requirementsSelected.includes(
                                            requirement.id as number
                                          )
                                      )
                                    }
                                    onChange={handleAllCheckBoxChange}
                                  />
                                </div>
                              </th>
                            </ProtectedSection>
                          )}
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            SERIAL / NÚMERO DO CHIP
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            TIPO
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            ESTADO
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            CRIADA EM
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            ATUALIZADA EM
                          </th>
                        </tr>
                      )}
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {requirements.map((requirement, index) => (
                        <tr
                          key={requirement.id}
                          className={
                            index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                          }
                        >
                          {[
                            ChipsRequirementStatuses.OPEN,
                            ChipsRequirementStatuses.IN_PROGRESS
                          ].includes(requirementsStatus) && (
                            <ProtectedSection
                              roles={[
                                Role.MANAGER,
                                Role.FINANCE,
                                Role.LOGISTICS
                              ]}
                            >
                              <td>
                                <div className="ml-5">
                                  <CheckBox
                                    name="selectItem"
                                    label=""
                                    checked={requirementsSelected.includes(
                                      requirement.id
                                    )}
                                    onChange={() =>
                                      handleCheckBoxChange(requirement.id)
                                    }
                                  />
                                </div>
                              </td>
                            </ProtectedSection>
                          )}
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1">
                                <div className="text-xs text-gray-900">
                                  {' '}
                                  {
                                    requirement.relationships?.chip.attributes
                                      .serial
                                  }{' '}
                                </div>
                                <div className="text-xs text-gray-500">
                                  {' '}
                                  {`+${requirement.relationships?.chip.attributes.number?.slice(
                                    0,
                                    2
                                  )} ${phone(
                                    requirement.relationships?.chip.attributes.number?.slice(
                                      2
                                    )
                                  )}`}{' '}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1">
                                <div className="text-xs text-gray-900">
                                  {
                                    translations['requirement_type'][
                                      requirement.attributes.type!
                                    ]
                                  }
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                            <div className="text-sm text-left font-semibold text-gray-900 truncate">
                              <Label
                                type="requirement_status"
                                label={
                                  translations['requirement_status'][
                                    requirement.attributes.status!
                                  ]
                                }
                                value={requirement.attributes.status}
                              />
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                            <div className="flex">
                              <div className="flex-1">
                                <div className="text-xs text-left font-semibold text-gray-900">
                                  {date(requirement.attributes.created_at)}
                                </div>
                                <div className="text-xs text-left text-gray-500">
                                  {time(requirement.attributes.created_at)}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                            <div className="flex">
                              <div className="flex-1">
                                <div className="text-xs text-left font-semibold text-gray-900">
                                  {date(requirement.attributes.updated_at)}
                                </div>
                                <div className="text-xs text-left text-gray-500">
                                  {time(requirement.attributes.updated_at)}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="mx-4">
              <Pagination
                currentPage={page}
                pageSize={maxItemsPerPage}
                itemsOnPage={requirements.length}
                totalItems={total}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
