export interface ChipsRequirement {
  id: number
  type: ChipsRequirementTypes
  status: ChipsRequirementStatuses
  chip_id: number
  created_at: Date
  updated_at: Date
  relationships?: Record<string, any>
}

export enum ChipsRequirementTypes {
  CANCELATION = 'cancelation',
  SUSPENDED = 'suspended',
  ACTIVATION = 'activation'
}

export enum ChipsRequirementStatuses {
  OPEN = 'open',
  IN_PROGRESS = 'in_progress',
  EXECUTED = 'executed',
  REFUSED = 'refused'
}
