import { toast } from 'react-toastify'
import { useParams, useHistory } from 'react-router-dom'
import { useCallback, useEffect, useRef, useState } from 'react'
import { RefreshIcon, TrashIcon, XIcon } from '@heroicons/react/outline'

import { LoadingCircle } from 'components/Loading'
import { StepPanel } from '../components/StepPanel'
import { NotFoundPage } from 'components/NotFoundPage'
import { ReviewTable } from '../components/ReviewTable'
import { ProtectedSection } from 'components/ProtectedSection'
import { useConfirmationDialog } from 'components/ConfirmationDialog'
import { CancelButton, SimpleButton } from 'components/FormInputs/Button'
import {
  InputWithValidationError,
  SimpleCustomSelect
} from 'components/FormInputs'

import config from 'config'
import { Errors } from 'types'
import { date, isEmpty } from 'utils'
import { showFreeChip } from 'services/chips'
import { translations } from '../translations'
import { ReadResult } from 'interfaces/queryOptions'
import {
  updateSteps,
  includeStatus,
  buildStatusDatesForStepsPanel
} from '../helpers/utils'
import { ConnectivityCart, ConnectivityDetails } from '../types'
import {
  orderConnectivityStatus,
  orderStatus,
  orderBaseSteps,
  nonLogisticsOrderBaseSteps,
  nonLogisticsOrderStatus,
  orderConnectivityStatusWhenItemIsSuspended
} from '../helpers/constants'
import {
  Chip,
  Client,
  ClientStatus,
  Currency,
  Installment,
  Order,
  OrderConnectivityStatus,
  OrderStatus,
  Plan,
  Role
} from 'models'
import {
  addItems,
  approve,
  cancel,
  deliver,
  listItems,
  removeItems,
  show,
  updateItemStatus,
  updateStatus
} from '../services'
import { Label } from 'components/Label'
import { isSameDay, setTodayTimeFrom } from 'utils/datetime'
import {
  determineCancelationMinDate,
  getOrderStatusAndDate
} from 'utils/order-status'
import { CheckBox } from 'modules/Clients/components/Tabs/Edit/Enterprise/components/CheckBox'
import { InstallmentTable } from '../components/Installments'
// import StatusPanel from "../components/StepPanel/StatusPanel"
import { show as showClient } from 'modules/Clients/services/read'
import { Cell, Pie, PieChart } from 'recharts'

const shouldFetchChips = (currentStatus: string) => {
  return !['pending', 'approved', 'in_line'].includes(currentStatus)
}

const makeUnknownChipDetails = (): ConnectivityDetails => {
  return {
    valid: false,
    saved: false,
    id: undefined,
    hint: () => (
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
        Validando
      </span>
    ),
    attributes: {}
  }
}

const makeValidChipDetails = (data: ReadResult<Chip>): ConnectivityDetails => {
  return {
    valid: true,
    saved: false,
    id: data.id,
    hint: () => (
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-emerald-100 text-emerald-800">
        Válido
      </span>
    ),
    attributes: data.attributes
  }
}

const makeInvalidChipDetails = (message: string): ConnectivityDetails => {
  return {
    valid: false,
    saved: false,
    id: undefined,
    hint: () => (
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
        {message}
      </span>
    ),
    attributes: {}
  }
}

const makeSavedChipDetails = (
  data: Chip & {
    itemStatus: OrderConnectivityStatus
    itemStatusCreatedAt: Date
  }
): ConnectivityDetails => {
  return {
    valid: true,
    saved: true,
    id: data.id,
    hint: () => (
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
        Salvo
      </span>
    ),
    attributes: data,
    status: data.itemStatus,
    status_updated_at: data.itemStatusCreatedAt
  }
}

export function ShowConnectivityOrder() {
  const { orderId } = useParams() as unknown as { orderId: number }

  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)
  const [disabled, setDisabled] = useState(false)

  const [steps, setSteps] = useState(
    orderBaseSteps.map((step) => Object.assign({}, step))
  )

  const [order, setOrder] = useState<Order>()
  const [currentOrderStatus, setCurrentOrderStatus] = useState<OrderStatus>()

  const [statusDates, setStatusDates] = useState<Record<string, any>>({})

  const [canceledAt, setCanceledAt] = useState(new Date())
  const canceledAtRef = useRef(canceledAt)

  const [itemUpdatedAt, setItemUpdatedAt] = useState(new Date())
  const itemUpdatedAtRef = useRef(itemUpdatedAt)

  const [itemToBeUpdatedStatus, setItemToBeUpdatedStatus] =
    useState<OrderConnectivityStatus>(OrderConnectivityStatus.ordered)
  const itemToBeUpdatedStatusRef = useRef(itemToBeUpdatedStatus)

  const [itemToBeUpdatedSerial, setItemToBeUpdatedSerial] = useState<string>('')

  const [newOrderStatusUpdatedAt, setNewOrderStatusUpdatedAt] = useState(
    new Date()
  )
  const newOrderStatusUpdatedAtRef = useRef(newOrderStatusUpdatedAt)

  const [newOrderStatus, setNewOrderStatus] = useState<OrderStatus>()
  const newOrderStatusRef = useRef(newOrderStatus)

  const [cartDetails, setCartDetails] = useState<ConnectivityCart>({})
  const cartDetailsRef = useRef(cartDetails)

  const [itemsSelected, setItemsSelected] = useState<number[]>([])
  const itemsSelectedRef = useRef(itemsSelected)

  const [itemsNumberByStatus, setItemsNumberByStatus] =
    useState<Record<OrderConnectivityStatus, number>>()

  useEffect(() => {
    const statusCount: Record<OrderConnectivityStatus, number> = {
      [OrderConnectivityStatus.canceled]: 0,
      [OrderConnectivityStatus.returned]: 0,
      [OrderConnectivityStatus.ordered]: 0,
      [OrderConnectivityStatus.suspended]: 0
    }

    Object.values(cartDetails).forEach((item) => {
      const status = item.status as OrderConnectivityStatus
      if (status in statusCount) {
        statusCount[status]++
      }
    })

    setItemsNumberByStatus(statusCount)
  }, [cartDetails])

  const handleCheckBoxChange = (id: number) => {
    setItemsSelected((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
    )
  }

  const handleAllCheckBoxChange = () => {
    const allIds = Object.values(cartDetails)
      .filter((item) => item.status === 'ordered')
      .map((item) => item.id as number)

    setItemsSelected((prev) =>
      allIds.every((id) => prev.includes(id)) ? [] : allIds
    )
  }

  const [installments, setInstallments] = useState<Installment[]>([])

  useEffect(() => {
    canceledAtRef.current = canceledAt
    itemUpdatedAtRef.current = itemUpdatedAt
    itemToBeUpdatedStatusRef.current = itemToBeUpdatedStatus
    newOrderStatusUpdatedAtRef.current = newOrderStatusUpdatedAt
    newOrderStatusRef.current = newOrderStatus
    cartDetailsRef.current = cartDetails
    itemsSelectedRef.current = itemsSelected
  }, [
    canceledAt,
    itemUpdatedAt,
    itemToBeUpdatedStatus,
    itemToBeUpdatedSerial,
    newOrderStatusUpdatedAt,
    newOrderStatus,
    cartDetails,
    itemsSelected
  ])

  const [plan, setPlan] = useState<Partial<Plan>>()
  const [client, setClient] = useState<Partial<Client>>()

  const [clientStatus, setClientStatus] = useState<ClientStatus>()

  const [serials, setSerials] = useState<Set<string>>(new Set())

  const [currentSerial, setCurrentSerial] = useState<string>()
  const [chipsUnderValidation, setChipsUnderValidation] = useState<Set<string>>(
    new Set()
  )
  const [validationQueue, setValidationQueue] = useState<string[]>([])

  const [showConfirmationDialog] = useConfirmationDialog()

  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setNewOrderStatusUpdatedAt(
      setTodayTimeFrom(
        new Date(
          getOrderStatusAndDate({
            id: order?.id as number,
            type: 'orders',
            attributes: { ...order }
          }).date!
        )
      )
    )
  }, [order])

  const updateOrderStatus = useCallback(
    (currentStatus: OrderStatus) => {
      setCurrentOrderStatus(currentStatus)
      setSteps([...updateSteps(steps, currentStatus)])

      return currentStatus
    },
    [steps]
  )

  const setFocus = useCallback(() => {
    const element = document.getElementById('serial')
    element?.focus()
  }, [])

  useEffect(() => {
    if (!orderId) return

    const fetchOrderChips = async (data: Record<string, any>[]) => {
      const details: ConnectivityCart = {}

      const orderItemDataMap = data.reduce((map, d) => {
        map[d.attributes.item_id] = d.attributes
        return map
      }, {} as Record<string, any>)

      try {
        const items = await listItems(orderId)

        for (const item of items.data) {
          details[item.attributes.serial as string] = makeSavedChipDetails({
            ...item.attributes,
            id: item.id,
            itemStatus: orderItemDataMap[item.id].status,
            itemStatusCreatedAt:
              item.relationships.order_chips[0].attributes.created_at
          } as any)
          serials.add(item.attributes.serial as string)
        }

        setCartDetails({ ...cartDetails, ...details })
        setSerials(new Set(serials))
      } catch (err) {
        toast.error('Falha ao buscar os chips do pedido')
      }
    }

    const fetchOrder = async () => {
      try {
        const order = await show(orderId, {
          includes: {
            client: ['id'],
            plan: ['id', 'name', 'meta', 'logistics', 'currency', 'amount'],
            order_chips: ['item_id', 'status'],
            coupon: ['*'],
            order_installments: ['*']
          }
        })

        if (order.relationships?.order_installments) {
          setInstallments(
            order.relationships.order_installments.map(
              ({ attributes }: any) => ({
                type: attributes.type,
                amount: attributes.amount,
                due_at: attributes.due_at,
                upfront: attributes.upfront,
                eligible: attributes.eligible
              })
            )
          )
        }

        setOrder(order as Order)

        setPlan({
          id: order?.relationships?.plan?.id,
          ...(order?.relationships?.plan?.attributes ?? {})
        })

        const client = await showClient(order?.relationships?.client?.id, {
          includes: { statuses: ['*'] }
        })

        setClient({ id: client.id, ...client.attributes })
        setClientStatus(client.relationships?.statuses[0].attributes.status)

        const currentStatus = updateOrderStatus(
          order.current_status as OrderStatus
        )

        setStatusDates(buildStatusDatesForStepsPanel(order))

        if (!order.relationships?.plan.attributes.logistics) {
          setSteps(updateSteps(nonLogisticsOrderBaseSteps, currentStatus))
        }

        if (shouldFetchChips(currentStatus)) {
          await Promise.all([
            fetchOrderChips(order?.relationships?.order_chips ?? [])
          ])
        }

        setIsLoading(false)
      } catch (err: any) {
        history.push('/orders')
        toast.error(err.suggestedMessage ?? 'Falha ao buscar o pedido')
      }
    }

    fetchOrder()
  }, [orderId])

  const onCancelConfirmation = useCallback(async () => {
    try {
      const cancelDate = canceledAtRef.current
      if (isSameDay(new Date(), cancelDate)) {
        await cancel(orderId)
      } else {
        await cancel(orderId, undefined, cancelDate)
      }
      const data = await show(orderId, {
        attributes: ['id', 'current_status']
      })

      const orderStatus = data.current_status as OrderStatus
      updateOrderStatus(orderStatus)
      toast.success('Pedido cancelado com sucesso!')
    } catch (err: any) {
      toast.error(err.suggestedMessage ?? 'Não foi possível cancelar o pedido!')
    }
  }, [orderId, updateOrderStatus])

  const onCancelRequest = useCallback(() => {
    showAndUpdateConfirmationDialog(cancelOrderModalAttributes, canceledAt)
  }, [onCancelConfirmation, showConfirmationDialog, canceledAt])

  const onApprovalConfirmation = useCallback(async () => {
    const newOrderStatusUpdatedAt = newOrderStatusUpdatedAtRef.current
    try {
      await approve(orderId, newOrderStatusUpdatedAt)
      const data = await show(orderId, {
        includes: {
          client: ['id', 'account_name'],
          plan: [
            'id',
            'name',
            'fiscal_data',
            'meta',
            'nature',
            'logistics',
            'currency',
            'amount'
          ],
          order_devices: [
            'item_id',
            'status',
            'write_off_date',
            'write_off_invoice',
            'write_off_invoice_motive'
          ],
          coupon: ['*']
        }
      })

      setOrder(data as Order)

      setStatusDates(buildStatusDatesForStepsPanel(data))

      const orderStatus = data.current_status as OrderStatus
      updateOrderStatus(orderStatus)
      toast.success('Pedido aprovado com sucesso!')
    } catch (err: any) {
      toast.error(err.suggestedMessage ?? 'Não foi possível aprovar o pedido!')
    }
  }, [orderId, updateOrderStatus])

  const onApprovalRequest = useCallback(() => {
    showAndUpdateConfirmationDialog(
      approveOrderModalAttributes,
      newOrderStatusUpdatedAt,
      'status_update'
    )
  }, [onApprovalConfirmation, showConfirmationDialog, newOrderStatusUpdatedAt])

  const onStatusChangeConfirmation = useCallback(async () => {
    const newStatus = newOrderStatusRef.current
    const newOrderStatusUpdatedAt = newOrderStatusUpdatedAtRef.current
    try {
      newStatus === OrderStatus.delivered
        ? await deliver(orderId, newOrderStatusUpdatedAt)
        : await updateStatus(
            orderId,
            newStatus as OrderStatus,
            newOrderStatusUpdatedAt
          )

      const data = await show(orderId, {
        includes: {
          client: ['id', 'account_name'],
          plan: [
            'id',
            'name',
            'fiscal_data',
            'meta',
            'nature',
            'logistics',
            'currency',
            'amount'
          ],
          order_devices: [
            'item_id',
            'status',
            'write_off_date',
            'write_off_invoice',
            'write_off_invoice_motive'
          ],
          coupon: ['*']
        }
      })

      setOrder(data as Order)

      setStatusDates(buildStatusDatesForStepsPanel(data))

      const orderStatus = data?.current_status as OrderStatus
      updateOrderStatus(orderStatus)

      toast.success('Estado atualizado com sucesso!')
    } catch (err: any) {
      toast.error(
        err.suggestedMessage ?? 'Não foi possível atualizar o estado do pedido!'
      )
    }
  }, [orderId, updateOrderStatus])

  const onStatusChangeRequest = useCallback(
    (status: OrderStatus | '') => {
      if (status === '') return

      setNewOrderStatus(status)

      showAndUpdateConfirmationDialog(
        getUpdateOrderStatusModalAttributes(status),
        newOrderStatusUpdatedAt,
        'status_update'
      )
    },
    [
      onStatusChangeConfirmation,
      showConfirmationDialog,
      newOrderStatusUpdatedAt
    ]
  )

  const onCanceledAtChange = useCallback(
    (value: string) => {
      showAndUpdateConfirmationDialog(cancelOrderModalAttributes, value)

      setCanceledAt(new Date(value))
    },
    [canceledAt, order]
  )

  const onItemStatusUpdatedAtChange = useCallback(
    (value: string) => {
      showAndUpdateConfirmationDialog(
        getUpdateItemStatusModalAttributes(itemToBeUpdatedStatusRef.current),
        value,
        'partial_cancelation'
      )

      setItemUpdatedAt(new Date(value))
    },
    [order, itemToBeUpdatedStatus]
  )

  const onApprovedAtChange = useCallback(
    (value: string) => {
      showAndUpdateConfirmationDialog(
        approveOrderModalAttributes,
        value,
        'status_update'
      )

      setNewOrderStatusUpdatedAt(new Date(value))
    },
    [order, newOrderStatus]
  )

  const onOrderStatusUpdatedAtChange = useCallback(
    (value: string) => {
      const status = newOrderStatusRef.current

      showAndUpdateConfirmationDialog(
        getUpdateOrderStatusModalAttributes(status as string),
        value,
        'status_update'
      )

      setNewOrderStatusUpdatedAt(new Date(value))
    },
    [order, newOrderStatus]
  )

  function showAndUpdateConfirmationDialog(
    attributes: Record<string, any>,
    date: Date | string,
    operationType?: string
  ) {
    const orderedAt = new Date(order?.ordered_at as string)
    const deliveryDate = order?.activated_at
      ? new Date(order.activated_at)
      : undefined
    const now = new Date()

    let minDate
    const maxDate = now

    if (operationType && operationType === 'status_update') {
      minDate = new Date(
        getOrderStatusAndDate({
          id: order?.id as number,
          type: 'orders',
          attributes: { ...order }
        }).date as Date
      )
    } else {
      minDate = determineCancelationMinDate(deliveryDate, orderedAt as Date)
    }

    showConfirmationDialog({
      title: attributes.title,
      message: attributes.message,
      onConfirm: attributes.onConfirm,
      datePicker: true,
      datePickerAttributes: {
        minDate: minDate as Date,
        maxDate: maxDate as Date
      },
      date: new Date(date),
      onDateChange: attributes.onDateChange
    })
  }

  const validateChip = useCallback(
    async (serial: string) => {
      if (
        chipsUnderValidation.has(serial) ||
        validationQueue.includes(serial)
      ) {
        return
      } else if (
        chipsUnderValidation.size < config.max_concurrent_validations
      ) {
        chipsUnderValidation.add(serial)
        setChipsUnderValidation(new Set(chipsUnderValidation))
        setCartDetails({ ...cartDetails, [serial]: makeUnknownChipDetails() })
      } else {
        setValidationQueue([...validationQueue, serial])
        setCartDetails({ ...cartDetails, [serial]: makeUnknownChipDetails() })
        return
      }

      const chip = await showFreeChip({
        filters: [
          { key: 'serial', value: serial },
          { key: 'from_softruck', value: true },
          { key: 'enterprise_id', op: 'is', value: 'null' }
        ]
      })

      // The user has removed this chip from the cart while it was still under validation
      if (!chipsUnderValidation.has(serial)) return
      chipsUnderValidation.delete(serial)
      setChipsUnderValidation(new Set(chipsUnderValidation))

      if (chip && plan?.meta?.restriction.chips.service_provider) {
        if (
          chip.attributes.service_provider !==
          plan?.meta?.restriction.chips.service_provider
        ) {
          cartDetails[serial] = makeInvalidChipDetails('Inválido')
        } else {
          cartDetails[serial] = makeValidChipDetails(chip)
        }
      } else {
        cartDetails[serial] = makeInvalidChipDetails('Inválido')
      }

      if (validationQueue.length) {
        const nextSerial = validationQueue.shift() as string
        chipsUnderValidation.add(nextSerial)
        cartDetails[nextSerial] = makeUnknownChipDetails()
        setValidationQueue([...validationQueue])
      }

      setDisabled(false)
      setFocus()
      setCartDetails({ ...cartDetails })
      setChipsUnderValidation(new Set(chipsUnderValidation))
    },
    [
      cartDetails,
      chipsUnderValidation,
      plan?.meta?.restriction.chips.service_provider,
      setFocus,
      validationQueue
    ]
  )

  const onAddToCart = useCallback(
    async (serial?: string) => {
      if (!serial || serial === '') return

      const pattern = /\d{20}/
      if (!serial.match(pattern)) {
        setErrors({ serial: 'O serial informado deve conter 20 dígitos!' })
        return
      }

      if (serials.has(serial)) {
        setErrors({ serial: 'O chip já está no pedido!' })
        return
      }

      if (serials.size >= (order?.quantity ?? 0)) {
        toast.error('O pedido já está cheio!')
        return
      }

      setDisabled(true)

      serials.add(serial)
      setSerials(new Set(serials))

      await validateChip(serial)
      setCurrentSerial('')
      setErrors({ serial: null })
    },
    [order?.quantity, serials, validateChip]
  )

  const onRemoveFrontCart = useCallback(
    (serial: string) => {
      serials.delete(serial)
      setSerials(new Set(serials))
      setErrors({ serial: null })

      const validationIndex = validationQueue.indexOf(serial)

      if (validationIndex >= 0) {
        validationQueue.splice(validationIndex, 1)
        setValidationQueue([...validationQueue])
      }

      if (chipsUnderValidation.has(serial)) {
        chipsUnderValidation.delete(serial)
        setChipsUnderValidation(new Set(chipsUnderValidation))
      }

      if (cartDetails[serial]) {
        delete cartDetails[serial]
        setCartDetails({ ...cartDetails })
      }
    },
    [cartDetails, chipsUnderValidation, serials, validationQueue]
  )

  const onRemoveFromOrderConfirmation = useCallback(
    async (serial: string) => {
      if (!cartDetails[serial] || !cartDetails[serial].id) {
        toast.error('Não foi possível remover o chip do pedido')
        return
      }

      try {
        await removeItems(orderId, [cartDetails[serial].id as number])
        toast.success('Chip removido com sucesso!')

        serials.delete(serial)
        setSerials(new Set(serials))

        delete cartDetails[serial]
        setCartDetails({ ...cartDetails })
      } catch (err: any) {
        toast.error(
          err.suggestedMessage ?? 'Não foi possível remover o chip do pedido'
        )
      }
    },
    [cartDetails, orderId, serials]
  )

  const onRemoveFromOrderRequest = useCallback(
    (serial: string) => {
      showConfirmationDialog({
        title: 'Remover item do pedido',
        message: `Tem certeza que deseja remover o chip '${serial}' do pedido?`,
        onConfirm: () => onRemoveFromOrderConfirmation(serial)
      })
    },
    [onRemoveFromOrderConfirmation, showConfirmationDialog]
  )

  const onSave = useCallback(async () => {
    try {
      const cartItems = Array.from(serials)

      if (cartItems.length <= 0) return

      const cartContainsInvalidItems = cartItems.find(
        (item) => cartDetails[item].id === undefined
      )

      if (cartContainsInvalidItems) {
        toast.error('Revise os chips inválidos e tente novamente!')
      } else {
        const newItems = cartItems.filter((item) => !cartDetails[item].saved)

        if (newItems.length <= 0) return

        await addItems(
          orderId,
          newItems.map((item) => cartDetails[item].id as number)
        )
        toast.success('Chips adicionados com sucesso!')

        const newItemsDetails = newItems.reduce((cart, item) => {
          cart[item] = makeSavedChipDetails({
            id: cartDetails[item].id as number,
            ...cartDetails[item].attributes,
            itemStatus: OrderConnectivityStatus.ordered
          } as any)
          return cart
        }, {} as ConnectivityCart)

        setCartDetails({
          ...cartDetails,
          ...newItemsDetails
        })
      }
    } catch (err: any) {
      toast.error(
        err.suggestedMessage ?? 'Falha ao tentar adicionar os chips ao pedido!'
      )
    }
  }, [cartDetails, orderId, serials])

  const onSaveChangeItemStatus = useCallback(async () => {
    try {
      const updateDate = itemUpdatedAtRef.current
      const newStatus = itemToBeUpdatedStatusRef.current
      const itemIdsSelected = itemsSelectedRef.current

      const cartDetails = cartDetailsRef.current

      const data = itemIdsSelected.map((i) => ({
        attributes: {
          item_id: i,
          created_at: updateDate
        }
      }))

      await updateItemStatus(orderId, newStatus, data)

      toast.success(
        `Chip(s) ${translations.item_status[newStatus]}(s) com sucesso!`
      )

      Object.values(cartDetails).forEach((item) => {
        if (itemIdsSelected.includes(item.id as number)) {
          item.status = newStatus
          item.status_updated_at = updateDate
        }
      })

      setItemsSelected([])

      setCartDetails({ ...cartDetails })
    } catch (err: any) {
      toast.error(
        err.suggestedMessage ??
          'Não foi possível atualizar o(s) estado(s) do(s) chips(s)!'
      )
    }
  }, [cartDetails, orderId])

  const onChangeItemStatusRequest = useCallback(
    async (newStatus: OrderConnectivityStatus, serial?: string) => {
      setItemToBeUpdatedStatus(newStatus)

      if (serial) setItemToBeUpdatedSerial(serial)

      showAndUpdateConfirmationDialog(
        getUpdateItemStatusModalAttributes(newStatus),
        itemUpdatedAt,
        'partial_cancelation'
      )
    },
    [onSaveChangeItemStatus, showConfirmationDialog, itemUpdatedAt]
  )

  const cancelOrderModalAttributes = {
    title: 'Cancelar pedido',
    message: 'Tem certeza que deseja cancelar este pedido?',
    onConfirm: onCancelConfirmation,
    onDateChange: onCanceledAtChange
  }

  function getUpdateItemStatusModalAttributes(
    itemToBeUpdatedStatus: OrderConnectivityStatus
  ) {
    return {
      title: 'Atualizar estado dos chips',
      message: `Tem certeza que deseja atualizar o(s) estado(s) do(s) chip(s) selecionado(s) para '${translations.item_status[itemToBeUpdatedStatus]}'?`,
      onConfirm: onSaveChangeItemStatus,
      onDateChange: onItemStatusUpdatedAtChange
    }
  }

  const approveOrderModalAttributes = {
    title: 'Aprovar pedido',
    message: 'Tem certeza que deseja aprovar este pedido?',
    onConfirm: onApprovalConfirmation,
    onDateChange: onApprovedAtChange
  }

  const getUpdateOrderStatusModalAttributes = (status: string) => ({
    title: 'Atualizar estado',
    message: `Alterar o estado do pedido para: ${translations.order_status[status]}?`,
    onConfirm: onStatusChangeConfirmation,
    onDateChange: onOrderStatusUpdatedAtChange
  })

  const isSuspendedItemSelected = (): boolean => {
    return Object.values(cartDetails).some(
      (detail) =>
        itemsSelected.includes(detail.id as number) &&
        detail.status === OrderConnectivityStatus.suspended
    )
  }

  return isLoading ? (
    <LoadingCircle />
  ) : !order ? (
    <NotFoundPage />
  ) : (
    <div className="overflow-hidden px-4">
      <div className="flex flex-row justify-between py-4 m-4 animate-fade-in-down">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Pedido: {order.token}{' '}
          {currentOrderStatus === 'canceled' && (
            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              {`Cancelado em ${date(order.canceled_at as Date)}`}
            </span>
          )}
        </h3>

        {![
          ClientStatus.canceled,
          ClientStatus.not_renewed,
          ClientStatus.restricted
        ].includes(clientStatus as ClientStatus) && (
          <div className="gap-x-2 flex flex-row justify-between">
            {includeStatus(currentOrderStatus, [OrderStatus.pending]) && (
              <ProtectedSection
                roles={[Role.MANAGER, Role.FINANCE, Role.LOGISTICS]}
              >
                <SimpleButton onClick={onApprovalRequest}>Aprovar</SimpleButton>
              </ProtectedSection>
            )}
            {!includeStatus(currentOrderStatus, [
              OrderStatus.pending,
              OrderStatus.canceled,
              OrderStatus.delivered
            ]) && (
              <ProtectedSection roles={[Role.MANAGER, Role.LOGISTICS]}>
                <div className="w-52">
                  <SimpleCustomSelect
                    options={
                      order.relationships?.plan.attributes.logistics
                        ? orderStatus
                        : nonLogisticsOrderStatus
                    }
                    value={currentOrderStatus}
                    onChange={(value) =>
                      onStatusChangeRequest(value as OrderStatus)
                    }
                  />
                </div>
              </ProtectedSection>
            )}
          </div>
        )}
      </div>

      <StepPanel steps={steps} statusDates={statusDates} />

      {/* <div className="py-4"></div> */}
      {/* <StatusPanel orderId={id} currentStatus={currentOrderStatus}/> */}

      <div className="flex">
        <div className="w-full">
          <ReviewTable order={order} client={client} plan={plan} />
        </div>

        <ProtectedSection roles={[Role.MANAGER, Role.FINANCE, Role.SALES]}>
          {installments.length > 0 && (
            <div className="mx-4 my-8 w-1/2">
              <InstallmentTable
                type={'Chips'}
                currency={plan?.currency ?? Currency.brl}
                installments={installments}
              />
            </div>
          )}
        </ProtectedSection>
      </div>

      {/* Add chips section */}
      <ProtectedSection roles={[Role.MANAGER, Role.LOGISTICS]}>
        {includeStatus(currentOrderStatus, [
          OrderStatus.in_progress,
          OrderStatus.delivered
        ]) &&
          currentOrderStatus === OrderStatus.in_progress && (
            <>
              <div className="flex p-6 m-4 rounded border shadow space-x-4 items-center animate-fade-in-down">
                <div className="w-1/4">
                  <InputWithValidationError
                    autoFocus
                    label=""
                    type="text"
                    name="serial"
                    id="serial"
                    placeholder="serial"
                    value={currentSerial}
                    disabled={
                      disabled || serials.size >= (order?.quantity ?? 0)
                    }
                    onKeyPress={(e: any) =>
                      e.key === 'Enter' ? onAddToCart(currentSerial) : null
                    }
                    onChange={(value) => setCurrentSerial(value)}
                    error={errors?.serial}
                  />
                </div>
                <div className="mt-1">
                  <SimpleButton
                    disabled={serials.size >= (order?.quantity ?? 0)}
                    onClick={() => onAddToCart(currentSerial)}
                  >
                    Adicionar
                  </SimpleButton>
                </div>
                <div className="mt-1">
                  {`Adicionados: ${serials.size} de ${order.quantity}`}
                </div>
              </div>
            </>
          )}
      </ProtectedSection>

      {!isEmpty(cartDetails) && currentOrderStatus === OrderStatus.delivered && (
        <div className="flex items-center mb-2 py-2 bg-gray-50 sm:px-4 lg:px-6 mx-4 shadow border-gray-200 sm:rounded-lg font-medium text-sm">
          <div className="flex justify-center mr-5">
            <PieChart width={28} height={28}>
              <Pie
                data={[
                  {
                    name: 'Ativos',
                    value: itemsNumberByStatus?.ordered || 0,
                    color: '#10B981'
                  },
                  {
                    name: 'Suspensos',
                    value: itemsNumberByStatus?.suspended || 0,
                    color: '#F97316'
                  },
                  {
                    name: 'Devolvidos',
                    value: itemsNumberByStatus?.returned || 0,
                    color: '#6B7280'
                  },
                  {
                    name: 'Cancelados',
                    value: itemsNumberByStatus?.canceled || 0,
                    color: '#DC2626'
                  }
                ]}
                cx="50%"
                cy="50%"
                innerRadius={7}
                outerRadius={14}
                fill="#8884d8"
                dataKey="value"
              >
                {[
                  { color: '#10B981' },
                  { color: '#F36F11' },
                  { color: '#6B7280' },
                  { color: '#DC2626' }
                ].map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
            </PieChart>
          </div>

          <div className="flex items-center w-full gap-x-8 text-center ">
            {(() => {
              const totalItems =
                (itemsNumberByStatus?.ordered || 0) +
                (itemsNumberByStatus?.suspended || 0) +
                (itemsNumberByStatus?.returned || 0) +
                (itemsNumberByStatus?.canceled || 0)
              return (
                <>
                  <div className="text-sm">
                    <div className="font-bold">
                      Total: {totalItems} (
                      {Math.round((totalItems / totalItems) * 100)}%)
                    </div>
                  </div>

                  <div className="text-emerald-600">{`Ativos: ${
                    itemsNumberByStatus?.ordered
                  } (${Math.round(
                    ((itemsNumberByStatus?.ordered || 0) / totalItems) * 100
                  )}%)`}</div>
                  <div className="text-orange-700">{`Suspensos: ${
                    itemsNumberByStatus?.suspended
                  } (${Math.round(
                    ((itemsNumberByStatus?.suspended || 0) / totalItems) * 100
                  )}%)`}</div>
                  <div className="text-gray-500">{`Devolvidos: ${
                    itemsNumberByStatus?.returned
                  } (${Math.round(
                    ((itemsNumberByStatus?.returned || 0) / totalItems) * 100
                  )}%)`}</div>
                  <div className="text-red-700">{`Cancelados: ${
                    itemsNumberByStatus?.canceled
                  } (${Math.round(
                    ((itemsNumberByStatus?.canceled || 0) / totalItems) * 100
                  )}%)`}</div>
                </>
              )
            })()}
          </div>
        </div>
      )}

      <div id="order-items-table">
        {!isEmpty(cartDetails) &&
          !includeStatus(currentOrderStatus, [
            OrderStatus.pending,
            OrderStatus.approved,
            OrderStatus.in_line
          ]) && (
            <div className="flex flex-col px-4 animate-fade-in-down">
              <div className="-my-2 sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        {itemsSelected.length > 0 ? (
                          <tr>
                            <th
                              colSpan={
                                includeStatus(currentOrderStatus, [
                                  OrderStatus.delivered
                                ])
                                  ? 9
                                  : 8
                              }
                            >
                              <div className="flex items-center space-x-4 pl-3">
                                <div className="ml-2">
                                  <CheckBox
                                    disabled={Object.values(cartDetails).every(
                                      (item) =>
                                        item.status !== 'ordered' &&
                                        item.status !== 'suspended'
                                    )}
                                    name="selectAllItems"
                                    label=""
                                    checked={Object.values(cartDetails)
                                      .filter(
                                        (item) =>
                                          item.status === 'ordered' ||
                                          item.status === 'suspended'
                                      )
                                      .every((item) =>
                                        itemsSelected.includes(
                                          item.id as number
                                        )
                                      )}
                                    onChange={handleAllCheckBoxChange}
                                  />
                                </div>
                                <ProtectedSection
                                  roles={[
                                    Role.MANAGER,
                                    Role.FINANCE,
                                    Role.LOGISTICS
                                  ]}
                                >
                                  <div className="w-full font-normal text-md pr-3">
                                    <SimpleCustomSelect
                                      options={[
                                        {
                                          value: undefined,
                                          label:
                                            'Escolher ação para os itens selecionados'
                                        },
                                        ...(isSuspendedItemSelected()
                                          ? orderConnectivityStatusWhenItemIsSuspended
                                          : orderConnectivityStatus)
                                      ]}
                                      onChange={(value) =>
                                        onChangeItemStatusRequest(
                                          value as OrderConnectivityStatus
                                        )
                                      }
                                      buttonClassNameExtras="h-9"
                                    />
                                  </div>
                                </ProtectedSection>
                              </div>
                            </th>
                          </tr>
                        ) : (
                          <tr>
                            {includeStatus(currentOrderStatus, [
                              OrderStatus.delivered
                            ]) && (
                              <ProtectedSection
                                roles={[
                                  Role.MANAGER,
                                  Role.FINANCE,
                                  Role.LOGISTICS
                                ]}
                              >
                                <th
                                  scope="col"
                                  className="pb-3 pr-3 pt-3 text-left text-xs font-medium text-gray-500 uppercase"
                                >
                                  <div className="ml-5">
                                    <CheckBox
                                      disabled={Object.values(
                                        cartDetails
                                      ).every(
                                        (item) =>
                                          item.status !== 'ordered' &&
                                          item.status !== 'suspended'
                                      )}
                                      name="selectAllItems"
                                      label=""
                                      checked={Object.values(cartDetails)
                                        .filter(
                                          (item) =>
                                            item.status === 'ordered' ||
                                            item.status === 'suspended'
                                        )
                                        .every((item) =>
                                          itemsSelected.includes(
                                            item.id as number
                                          )
                                        )}
                                      onChange={handleAllCheckBoxChange}
                                    />
                                  </div>
                                </th>
                              </ProtectedSection>
                            )}
                            <th
                              scope="col"
                              className="p-3 text-left text-xs font-medium text-gray-500 uppercase"
                            >
                              {includeStatus(currentOrderStatus, [
                                OrderStatus.in_line,
                                OrderStatus.in_progress,
                                OrderStatus.updating_fiscal_data
                              ])
                                ? 'Válido'
                                : 'Status'}
                            </th>
                            <th
                              scope="col"
                              className="p-3 text-left text-xs font-medium text-gray-500 uppercase"
                            >
                              Serial / Número
                            </th>
                            <th
                              scope="col"
                              className="p-3 text-left text-xs font-medium text-gray-500 uppercase"
                            >
                              Operadora
                            </th>
                            <th
                              scope="col"
                              className="p-3 text-left text-xs font-medium text-gray-500 uppercase"
                            >
                              APN
                            </th>
                            <th
                              scope="col"
                              className="p-3 text-left text-xs font-medium text-gray-500 uppercase"
                            >
                              APN - usuário
                            </th>
                            <th
                              scope="col"
                              className="p-3 text-left text-xs font-medium text-gray-500 uppercase"
                            >
                              APN - senha
                            </th>
                            {!includeStatus(currentOrderStatus, [
                              OrderStatus.delivered
                            ]) && (
                              <th
                                scope="col"
                                className="p-3 text-left text-xs font-medium text-gray-500 uppercase"
                              >
                                Ações
                              </th>
                            )}
                          </tr>
                        )}
                      </thead>
                      <tbody className="text-left bg-white divide-y divide-gray-200">
                        {Array.from(serials)
                          .reverse()
                          .map((serial, index) => (
                            <tr
                              key={serial}
                              className={index % 2 ? 'bg-gray-50' : 'bg-white'}
                            >
                              {includeStatus(currentOrderStatus, [
                                OrderStatus.delivered
                              ]) && (
                                <ProtectedSection
                                  roles={[
                                    Role.MANAGER,
                                    Role.FINANCE,
                                    Role.LOGISTICS
                                  ]}
                                >
                                  <td>
                                    <div className="ml-5">
                                      <CheckBox
                                        disabled={
                                          (cartDetails[serial].status !==
                                            'ordered' &&
                                            cartDetails[serial].status !==
                                              'suspended') ||
                                          (cartDetails[serial].status ===
                                            'suspended' &&
                                            Object.values(cartDetails).some(
                                              (detail) =>
                                                itemsSelected.includes(
                                                  detail.id as number
                                                ) &&
                                                detail.status ===
                                                  OrderConnectivityStatus.ordered
                                            )) ||
                                          (cartDetails[serial].status ===
                                            'ordered' &&
                                            Object.values(cartDetails).some(
                                              (detail) =>
                                                itemsSelected.includes(
                                                  detail.id as number
                                                ) &&
                                                detail.status ===
                                                  OrderConnectivityStatus.suspended
                                            ))
                                        }
                                        name="selectItem"
                                        label=""
                                        checked={itemsSelected.includes(
                                          cartDetails[serial].id! as number
                                        )}
                                        onChange={() =>
                                          handleCheckBoxChange(
                                            cartDetails[serial].id! as number
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                </ProtectedSection>
                              )}
                              <td className="pt-3 pb-3 pl-3 whitespace-nowrap">
                                <div className="flex items-center">
                                  {includeStatus(currentOrderStatus, [
                                    OrderStatus.in_line,
                                    OrderStatus.in_progress,
                                    OrderStatus.updating_fiscal_data
                                  ]) ? (
                                    cartDetails[serial].hint()
                                  ) : (
                                    <div>
                                      <Label
                                        type="item_status"
                                        label={
                                          translations['item_status'][
                                            cartDetails[serial].status as string
                                          ]
                                        }
                                        value={cartDetails[serial].status}
                                      />
                                      <div className="ml-2 text-xs text-left text-gray-500">
                                        {date(
                                          cartDetails[serial].status_updated_at
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td className="p-3 whitespace-nowrap">
                                <div className="text-xs text-gray-900">
                                  {cartDetails[serial].attributes.serial ||
                                    serial ||
                                    '-'}
                                </div>
                                <div className="text-xs text-gray-500">
                                  {cartDetails[serial].attributes.number || '-'}
                                </div>
                              </td>
                              <td className="p-3 whitespace-nowrap text-xs text-gray-500">
                                {cartDetails[serial].attributes
                                  .service_provider || '-'}
                              </td>
                              <td className="p-3 whitespace-nowrap text-xs text-gray-500">
                                {cartDetails[serial].attributes.apn || '-'}
                              </td>
                              <td className="p-3 whitespace-nowrap text-xs text-gray-500">
                                {cartDetails[serial].attributes.apn_username ||
                                  '-'}
                              </td>
                              <td className="p-3 whitespace-nowrap text-xs text-gray-500">
                                {cartDetails[serial].attributes.apn_password ||
                                  '-'}
                              </td>
                              {!includeStatus(currentOrderStatus, [
                                OrderStatus.delivered
                              ]) && (
                                <td className="p-3 whitespace-nowrap text-xs font-medium">
                                  {includeStatus(currentOrderStatus, [
                                    OrderStatus.in_progress
                                  ]) &&
                                    !cartDetails[serial].saved && (
                                      <button
                                        type="button"
                                        className="-ml-px relative inline-flex items-center px-3 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                        onClick={() => validateChip(serial)}
                                      >
                                        <RefreshIcon
                                          className="h-4 w-4"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    )}
                                  {includeStatus(currentOrderStatus, [
                                    OrderStatus.in_progress
                                  ]) ? (
                                    cartDetails[serial].saved ? (
                                      <button
                                        type="button"
                                        className="-ml-px relative inline-flex items-center px-3 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-red-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                        onClick={() =>
                                          onRemoveFromOrderRequest(serial)
                                        }
                                      >
                                        <TrashIcon
                                          className="h-4 w-4"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-red-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                        onClick={() =>
                                          onRemoveFrontCart(serial)
                                        }
                                      >
                                        <XIcon
                                          className="h-4 w-4"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    )
                                  ) : null}
                                </td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

        <ProtectedSection roles={[Role.MANAGER, Role.LOGISTICS]}>
          {includeStatus(currentOrderStatus, [OrderStatus.in_progress]) && (
            <div className="m-4 flex justify-end animate-fade-in-down">
              <button
                type="button"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none"
                onClick={() => onSave()}
              >
                Salvar
              </button>
            </div>
          )}
        </ProtectedSection>
      </div>

      {!includeStatus(currentOrderStatus, [OrderStatus.canceled]) && (
        <div className="py-6 gap-x-2 flex flex-row justify-between animate-fade-in-down">
          <ProtectedSection
            roles={[Role.MANAGER, Role.FINANCE, Role.LOGISTICS]}
          >
            <div className="ml-3 text-left text-md">
              <label className="font-medium text-gray-700">
                Cancelar pedido
              </label>
              <p className="p-4 text-gray-500">
                Atenção: ao cancelar o pedido todos os chips salvos serão
                deletados.
              </p>

              <div className="">
                <CancelButton
                  label="Cancelar"
                  onClick={onCancelRequest}
                ></CancelButton>
              </div>
            </div>
          </ProtectedSection>
        </div>
      )}
    </div>
  )
}
