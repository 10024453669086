import { Link } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import {
  OfficeBuildingIcon,
  NewspaperIcon,
  ShoppingCartIcon,
  CreditCardIcon,
  ClipboardListIcon,
  ColorSwatchIcon,
  HomeIcon,
  TicketIcon,
  RssIcon,
  UserGroupIcon,
  LinkIcon,
  CurrencyDollarIcon,
  BookOpenIcon,
  CashIcon
} from '@heroicons/react/outline'

import { Role } from 'models'
import { Routes } from './Routes'
import { classNames } from 'utils'
import { AuthContext } from 'modules/Auth'
import { ProtectedSection } from 'components/ProtectedSection'
import { BeakerIcon } from '@heroicons/react/solid'

type NavSection = {
  name?: string
  hiddenFor: Role[]
  tabs: {
    name: string
    to: string
    icon: JSX.Element
    isBeta: boolean
    hiddenFor: Role[]
  }[]
}

const homePageNavSection: NavSection = {
  hiddenFor: [],
  tabs: [
    {
      name: 'Página Inicial',
      to: '/',
      icon: <HomeIcon className="w-6 h-6 mr-2" />,
      isBeta: false,
      hiddenFor: []
    }
  ]
}

const managementNavSection: NavSection = {
  name: 'Gestão',
  hiddenFor: [],
  tabs: [
    {
      name: 'Usuários',
      to: '/staff-members',
      icon: <UserGroupIcon className="w-6 h-6 mr-2" />,
      isBeta: false,
      hiddenFor: []
    },
    {
      name: 'Acessos',
      to: '/access-plans',
      icon: <ClipboardListIcon className="w-6 h-6 mr-2" />,
      isBeta: false,
      hiddenFor: [Role.LOGISTICS]
    },
    {
      name: 'Planos',
      to: '/plans',
      icon: <NewspaperIcon className="w-6 h-6 mr-2" />,
      isBeta: false,
      hiddenFor: []
    }
  ]
}

const businessNavSection: NavSection = {
  name: 'Negócios',
  hiddenFor: [],
  tabs: [
    {
      name: 'Clientes',
      to: '/clients',
      icon: <OfficeBuildingIcon className="w-6 h-6 mr-2" />,
      isBeta: false,
      hiddenFor: [Role.LOGISTICS]
    },
    {
      name: 'Pedidos',
      to: '/orders',
      icon: <TicketIcon className="w-6 h-6 mr-2" />,
      isBeta: false,
      hiddenFor: []
    },
    {
      name: 'Assinaturas',
      to: '/subscriptions',
      icon: <ColorSwatchIcon className="w-6 h-6 mr-2" />,
      isBeta: false,
      hiddenFor: []
    }
  ]
}

const financialNavSection: NavSection = {
  name: 'Financeiro',
  hiddenFor: [Role.LOGISTICS, Role.SUPPORT],
  tabs: [
    {
      name: 'Cupons',
      to: '/coupons',
      icon: <CurrencyDollarIcon className="w-6 h-6 mr-2" />,
      isBeta: false,
      hiddenFor: [Role.LOGISTICS, Role.SUPPORT]
    },
    {
      name: 'Faturas',
      to: '/invoices',
      icon: <CashIcon className="w-6 h-6 mr-2" />,
      isBeta: true,
      hiddenFor: [Role.LOGISTICS]
    },
    {
      name: 'Solicitações',
      to: '/requirements',
      icon: <BookOpenIcon className="w-6 h-6 mr-2" />,
      isBeta: false,
      hiddenFor: [
        Role.SUPPORT,
        Role.FINANCE,
        Role.LOGISTICS,
        Role.MANAGER,
        Role.SALES
      ]
    }
  ]
}

const logisticsNavSection: NavSection = {
  name: 'Logística',
  hiddenFor: [],
  tabs: [
    {
      name: 'Sim Cards',
      to: '/chips',
      icon: <CreditCardIcon className="w-6 h-6 mr-2" />,
      isBeta: false,
      hiddenFor: []
    },
    {
      name: 'Dispositivos',
      to: '/devices',
      icon: <RssIcon className="w-6 h-6 mr-2" />,
      isBeta: false,
      hiddenFor: []
    }
  ]
}

const supportNavSection: NavSection = {
  name: 'Suporte',
  hiddenFor: [Role.FINANCE],
  tabs: [
    {
      name: 'Navegação',
      to: '/support',
      icon: <LinkIcon className="w-6 h-6 mr-2" />,
      isBeta: false,
      hiddenFor: [Role.FINANCE]
    }
  ]
}

const navigation = [
  homePageNavSection,
  managementNavSection,
  businessNavSection,
  financialNavSection,
  logisticsNavSection,
  supportNavSection
]

function getRestrictions(roles: Role[]): Role[] {
  return Object.values(Role).filter((role) => !roles.includes(role))
}

export function Home({ history }: { history: any }) {
  const [current, setCurrent] = useState('')

  const context = useContext(AuthContext)

  useEffect(() => {
    return history.listen((location: any) => {
      const tabs = [
        ...homePageNavSection.tabs,
        ...managementNavSection.tabs,
        ...businessNavSection.tabs,
        ...financialNavSection.tabs,
        ...logisticsNavSection.tabs,
        ...supportNavSection.tabs
      ]
      setCurrent(
        tabs.find((tab) => tab.to === location.pathname)?.name ?? current
      )
    })
  }, [history, current])

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      {/* Static sidebar for desktop */}
      <div className="flex flex-shrink-0">
        <div className="flex flex-col w-60">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0">
            <div className="flex-1 flex flex-col py-5 overflow-y-auto">
              <Link to="/" className="flex items-center flex-shrink-0 px-4">
                <img
                  className="mx-auto h-12 w-auto"
                  src="billing-logo-96.svg"
                  alt="Workflow"
                />
              </Link>

              <nav className="flex-1 mt-6" aria-label="Sidebar">
                <ProtectedSection roles={[Role.MANAGER, Role.SALES]}>
                  <div className="flex mx-4">
                    <Link to="/orders/create" className="w-full flex">
                      <button className="h-10 w-full flex items-center justify-center text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none">
                        <div className="flex flex-row items-center justify-center">
                          <ShoppingCartIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                          <div className="mx-3">Novos Pedidos</div>
                        </div>
                      </button>
                    </Link>
                  </div>
                </ProtectedSection>

                <ul className="mt-6">
                  {navigation.map((section, index) => (
                    <ProtectedSection
                      roles={getRestrictions(section.hiddenFor)}
                      key={index}
                    >
                      {section.name && (
                        <div className="text-sm font-semibold text-gray-400 mx-4 px-2">
                          {section.name}
                        </div>
                      )}
                      {section.tabs.map((tab, index) => (
                        <li className={'px-2 mx-2 space-y-1'} key={index}>
                          <Link
                            to={tab.to}
                            onClick={() => setCurrent(tab.name)}
                            className={classNames(
                              tab.name === current
                                ? 'bg-gray-200 text-gray-900'
                                : 'bg-transparent',
                              'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                            )}
                          >
                            <div
                              className={`flex ${section.name ? 'ml-2' : ''}`}
                            >
                              {tab.icon}
                              {tab.name}
                              {tab.isBeta && (
                                <div className="ml-12 gap-x-1 flex items-center border border-dashed border-yellow-500 bg-yellow-50 text-yellow-700 px-2 py-0.5 rounded-md">
                                  <p className="text-xs font-medium">beta</p>
                                  <BeakerIcon className="w-3.5 h-3.5" />
                                </div>
                              )}
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ProtectedSection>
                  ))}
                </ul>
              </nav>
            </div>
            <div className="flex-shrink-0 flex p-4">
              <button className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="inline-block h-8 w-8 rounded-full"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                        d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                      {context?.user?.attributes.fullname}
                    </p>
                    <p
                      className="text-xs text-left font-medium text-gray-500 group-hover:text-gray-700"
                      onClick={() => context.logout()}
                    >
                      Logout
                    </p>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full flex-1 overflow-hidden">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <Routes />
          </div>
        </main>
      </div>
    </div>
  )
}
