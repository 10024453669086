const DEFAULT_LOCALE = 'pt-BR'

export function date(date?: Date | string): string {
  if (!date) return '-'

  const formatedDate = new Date(date)

  return formatedDate.toLocaleString(DEFAULT_LOCALE).substring(0, 10)
}

export function time(date?: Date | string): string {
  if (!date) return '-'

  const formatedDate = new Date(date)

  return `às ${formatedDate.toLocaleTimeString(DEFAULT_LOCALE).substring(0, 5)}`
}

export function billingCycle(day?: number): string {
  if (!day) return '-'

  const newDate = new Date().setDate(day)

  return new Date(newDate).toLocaleString(DEFAULT_LOCALE).substring(0, 10)
}

export const phone = (value?: string) => {
  if (!value) return ''

  const input = value.replace(/\D/g, '').substring(0, 11)
  const areaCode = input.substring(0, 2)
  let middle, last

  if (input.length === 11) {
    middle = input.substring(2, 7)
    last = input.substring(7, 11)
  } else {
    middle = input.substring(2, 6)
    last = input.substring(6, 11)
  }

  if (input.length > 6) {
    value = `(${areaCode}) ${middle} - ${last}`
  } else if (input.length > 2) {
    value = `(${areaCode}) ${middle}`
  } else if (input.length > 0) {
    value = `(${areaCode}`
  }

  return value
}

export function document(
  document: string | undefined,
  documentType: string | undefined
) {
  if (!document) return ''

  if (!documentType) return document

  return documentType === 'cnpj'
    ? document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    : document
}

export function amount(
  amount: string | number | undefined,
  withDecimals = true,
  withThousandsSeparator = false
) {
  if (!amount) return '0,00'

  const formatNumber = (value: number) => {
    const formatted = withDecimals
      ? (value / 100).toFixed(2).replace('.', ',')
      : value.toFixed(2).replace('.', ',')

    if (withThousandsSeparator) {
      const [intPart, decimalPart] = formatted.split(',')
      return (
        intPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
        (decimalPart ? ',' + decimalPart : '')
      )
    }

    return formatted
  }

  if (typeof amount === 'string') {
    return formatNumber(parseInt(amount))
  }

  return formatNumber(amount)
}

export function currency(currency: string | undefined) {
  const currencies: Record<string, string> = {
    brl: 'R$',
    usd: '$',
    eur: '€',
    uyi: '$U',
    ars: '$'
  }

  if (!currency) return currencies['usd']

  return currencies[currency]
}

export function formatCurrency(value: string) {
  if (value === '-0,00' || value === '-0,0') {
    return '0,00'
  } else {
    return amount(value.replace(/[^0-9]/g, ''))
  }
}
