import {
  BillingType,
  AddressType,
  Currency,
  Interval,
  Nature,
  PlanType,
  Subtype,
  TierMode,
  UsageType
} from 'models'
import { CouponDurationMode, CouponType } from 'models/coupon'

export const planTypes = [
  { value: PlanType.hardware, label: 'Hardware' },
  { value: PlanType.connectivity, label: 'Conectividade' }
]

export const couponTypes = [
  {
    id: 1,
    available: true,
    value: CouponType.software,
    label: 'Software',
    description: 'Cupom aplicável sobre assinaturas'
  },
  {
    id: 2,
    available: true,
    value: CouponType.hardware,
    label: 'Hardware',
    description: 'Cupom aplicável sobre pedidos de dispositivos'
  },
  {
    id: 3,
    available: true,
    value: CouponType.connectivity,
    label: 'Conectividade',
    description: 'Cupom aplicável sobre pedidos de chips'
  }
]

export const couponDurationModes = [
  { value: CouponDurationMode.forever, label: 'Permanente' },
  { value: CouponDurationMode.repeating, label: 'Recorrente' },
  { value: CouponDurationMode.once, label: 'Única' }
]

export const couponModes = [
  { value: 'fixed', label: 'Fixo' },
  { value: 'proportional', label: 'Proporcional' }
]

export const yesOrNo = [
  { value: 'yes', label: 'Sim' },
  { value: 'no', label: 'Não' }
]

export const accessPlanTypes = [
  {
    id: 1,
    available: true,
    value: UsageType.metered,
    label: 'Por consumo',
    description:
      'Acesso baseado no uso real do serviço, com cobrança proporcional ao consumo',
    detail: ''
  },
  {
    id: 2,
    available: true,
    value: UsageType.licensed,
    label: 'Licenças de uso',
    description: 'Acesso baseado no total adquirido de cada licença',
    detail: ''
  },
  {
    id: 3,
    available: true,
    value: UsageType.tiered,
    label: 'Faixas de uso',
    description: 'Acesso de acordo com cada faixa',
    detail: ''
  }
]

export const billingTypes = [
  { value: BillingType.postpaid, label: 'Pós pago' }
  /*{ value: BillingType.prepaid, label: 'Pré pago' }*/
]

export const currencies = [
  { value: Currency.brl, label: 'Real' },
  { value: Currency.usd, label: 'Dólar Americano' },
  { value: Currency.uyi, label: 'Peso Uruguaio' },
  { value: Currency.eur, label: 'Euro' },
  { value: Currency.ars, label: 'Peso Argentino' }
]

export const fineModes = [
  { value: 'fixed', label: 'Fixa' },
  { value: 'proportional', label: 'Proporcional' }
]

export const intervals = [
  { value: Interval.month, label: 'Mensal' },
  //{ value: Interval.day, label: 'Diária' },
  //{ value: Interval.week, label: 'Semanal' },
  { value: Interval.year, label: 'Anual' }
]

export const usageTypes = [
  { value: UsageType.licensed, label: 'Licença de uso' }
  //{ value: UsageType.metered, label: 'Medida' }
]

export const tierModes = [
  { value: TierMode.discount, label: 'Desconto' },
  //{ value: TierMode.graduated, label: 'Gradual' },
  { value: TierMode.volume, label: 'Volume' },
  { value: TierMode.package, label: 'Pacote' }
]

export const serviceProviders = [
  { value: Subtype.claro, label: 'Claro' },
  { value: Subtype.vivo, label: 'Vivo' },
  { value: Subtype.algar, label: 'Algar' },
  { value: Subtype.tim, label: 'Tim' },
  { value: Subtype.arqia, label: 'Arqia' }
]

export const deviceTypes = [
  { value: Subtype.softruck_s4g, label: 'Softruck S4G' },
  { value: Subtype.suntech_st310_u, label: 'Suntech 310u' },
  { value: Subtype.suntech_st310_uc2, label: 'Suntech 310uc2' },
  { value: Subtype.suntech_300hd, label: 'Suntech 300HD' },
  { value: Subtype.nt20, label: 'NT20' },
  { value: Subtype.nt40, label: 'NT40' },
  { value: Subtype.cr_4000_a, label: 'CR4000' },
  { value: Subtype.mt_2000, label: 'MT2000' },
  { value: Subtype.satelital, label: 'Globalstar SmartOne C' },
  { value: Subtype.y202, label: 'Y202' },
  { value: Subtype.j16, label: 'J16' },
  { value: Subtype.y18, label: 'Y18' },
  { value: Subtype.j14, label: 'J14' },
  { value: Subtype.queclink_gv50, label: 'Queclink GV50' },
  { value: Subtype.ev02, label: 'EV02' },
  { value: Subtype.suntech_st310_ulc, label: 'Suntech 310ulc' },
  { value: Subtype.e4_mini, label: 'E4 mini' }
]

export const hardwareNatures = [
  { value: Nature.service, label: 'Serviço' },
  { value: Nature.good, label: 'Bem' }
]

export const connectivityNatures = [{ value: Nature.service, label: 'Serviço' }]

export const addressTypes = [
  { value: AddressType.billing, label: 'Cobrança' },
  { value: AddressType.deliver, label: 'Entrega' },
  { value: AddressType.other, label: 'Outro' }
]
