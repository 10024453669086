import { useHistory } from 'react-router-dom'
import { PencilIcon } from '@heroicons/react/outline'
import { InformationCircleIcon } from '@heroicons/react/solid'
import { useMemo, useEffect, useState, useCallback } from 'react'

import config from 'config'
import { Role } from 'models'
import { Label } from 'components/Label'
import { Info } from './components/Info'
import { SearchBar } from 'components/Search'
import { useURLParams } from 'hooks/useURLParams'
import { LoadingCircle } from 'components/Loading'
import { Pagination } from 'components/Pagination'
import { translations } from 'modules/Clients/translations'
import { ProtectedSection } from 'components/ProtectedSection'
import { useViewClient } from 'modules/Clients/hooks/useViewClient'
import { UpdateChipStatus } from './components/UpdateChip'
import { QueryOptions } from 'interfaces/queryOptions'
import { SimpleCustomSelect } from 'components/FormInputs/Select/SimpleCustomSelect'
import { date } from 'utils'
import { SortDownIcon } from 'components/Table/SortDownIcon'
import { SorUptIcon } from 'components/Table/SortUpIcon'
import { SortIcon } from 'components/Table/SortIcon'

const statusFilters = [
  { value: '', label: 'Selecione um estado' },
  { value: 'ordered', label: 'Pedido' },
  { value: 'canceled', label: 'Cancelado' },
  { value: 'returned', label: 'Devolvido' },
  { value: 'suspended', label: 'Suspenso' }
]

export function ChipTab() {
  const { replace } = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(() => {
    const params: QueryOptions = {
      search: '',
      filters: []
    }

    const search = query.get('search')
    const filters = query.get('q')

    if (search) params.search = search
    if (filters) {
      params.filters = JSON.parse(atob(filters))
    }

    return params
  }, [query])

  const { client, fetchClient, isLoadingChips, chips, listChips } =
    useViewClient()

  const [index, setIndex] = useState<number>()
  const [isOpen, setIsOpen] = useState(false)
  const [isInfoOpen, setIsInfoOpen] = useState(false)

  const [isItemSuspended, setIsItemSuspended] = useState(false)

  const maxItemsPerPage = 100
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [lastPage, setLastPage] = useState(1)
  const [search, setSearch] = useState(queryParams.search)
  const [filter, setFilter] = useState(
    queryParams.filters?.length ? queryParams.filters[0].value : ''
  )

  const sortableValues = ['item_status_created_at']
  const [sortedValue, setSortedValue] = useState<string>(
    'item_status_created_at'
  )
  const [isSortedDesc, setIsSortedDesc] = useState(false)

  const [options, setOptions] = useState<QueryOptions>({
    page: 1,
    count: true,
    limit: maxItemsPerPage,
    search: queryParams.search,
    filters: queryParams.filters,
    sort: {
      item_status_created_at: -1
    }
  })

  useEffect(() => {
    if (!client) {
      fetchClient()
    }
  }, [client, fetchClient])

  useEffect(() => {
    if (!client) return

    listChips(options)
  }, [client, listChips, search, page, filter, options])

  useEffect(() => {
    if (!chips) return

    setTotal(chips.count)
  }, [chips])

  useEffect(() => {
    const newLastPage = Math.ceil(total / maxItemsPerPage)
    setLastPage(newLastPage)
  }, [maxItemsPerPage, total])

  useEffect(() => {
    if (options.filters && options.filters.length > 0) {
      setFilter(options.filters[0].value)
    } else {
      setFilter('')
    }
    setPage(options.page ?? 1)
    setSearch(options.search ?? '')
  }, [options.filters, options.page, options.search])

  const onFilter = useCallback(
    (value: string) => {
      const filters = []

      if (value === '') {
        query.delete('q')
      } else {
        filters.push({ key: 'item_status', op: 'eq', value: value })
        query.set('q', btoa(JSON.stringify(filters)))
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      setOptions({ ...options, filters })
    },
    [options, query, replace]
  )

  const onSort = useCallback(
    (sortBy: string) => {
      if (!sortableValues.includes(sortBy)) return
      const sortValue = isSortedDesc ? -1 : 1
      setOptions({ ...options, sort: { [sortBy]: sortValue } })
      setSortedValue(sortBy)
      setIsSortedDesc(!isSortedDesc)
    },
    [isSortedDesc]
  )

  const onPageChange = useCallback(
    (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setOptions({ ...options, page: newPage })
    },
    [lastPage, options]
  )

  const onSearch = useCallback(
    (term: string | undefined) => {
      if (!term || !term.length || term.length < config.min_search_length) {
        term = ''
      }

      term === '' ? query.delete('search') : query.set('search', term)

      const isSettingSearch = options.search === '' && term !== ''
      const isCleaningSearch = options.search !== '' && term === ''
      if (isSettingSearch || isCleaningSearch) {
        options.page = 1
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      options.search = term

      setOptions({ ...options })
    },
    [options, query, replace]
  )

  return (
    <div>
      <div>
        <div className="py-6 flex flex-row justify-between animate-fade-in-down">
          <SearchBar value={search ?? ''} onChange={onSearch} />

          <div className="w-1/5">
            <SimpleCustomSelect
              label=""
              options={statusFilters}
              value={filter}
              onChange={onFilter}
            />
          </div>
        </div>
        {isLoadingChips ? (
          <div className="py-8">
            <LoadingCircle />
          </div>
        ) : (
          <div>
            <div className="flex flex-col animate-fade-in-down">
              <div className="">
                <div className="py-2 inline-block min-w-full">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                          >
                            Código
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                          >
                            Pedido
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                          >
                            Serial
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                          >
                            Operadora
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                          >
                            APN
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase group cursor-pointer"
                            onClick={() => onSort('item_status_created_at')}
                          >
                            <div
                              className={'flex justify-between items-center'}
                            >
                              <div>Chip</div>
                              {sortedValue === 'item_status_created_at' ? (
                                isSortedDesc ? (
                                  <SortDownIcon className="h-4 w-4" />
                                ) : (
                                  <SorUptIcon className="h-4 w-4" />
                                )
                              ) : (
                                <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                              )}
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                          >
                            <button
                              type="button"
                              className=" text-blue-700 outline-none"
                              onClick={() => setIsInfoOpen(true)}
                            >
                              <InformationCircleIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-left bg-white divide-y divide-gray-200">
                        {chips?.data.map((chip, idx) => (
                          <tr
                            key={idx}
                            className={idx % 2 ? 'bg-gray-50' : 'bg-white'}
                          >
                            <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="flex-1 truncate">
                                  <div className="text-xs text-left font-semibold text-gray-900 hover:text-indigo-900">
                                    {chip.attributes.token || '-'}
                                  </div>
                                  <div className="text-xs text-left text-gray-500">
                                    {chip.attributes.batch || '-'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                              <div className="text-sm text-left font-semibold text-gray-900 truncate">
                                <Label
                                  type="order_status"
                                  label={
                                    translations['order_status'][
                                      chip.attributes.order_status as string
                                    ]
                                  }
                                  value={chip.attributes.order_status}
                                />
                              </div>
                              <div className="ml-2 text-xs text-left text-gray-500">
                                {' '}
                                {date(
                                  chip.attributes
                                    .order_status_created_at as Date
                                )}{' '}
                              </div>
                            </td>
                            <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="flex-1 truncate">
                                  <div className="text-xs text-left font-semibold text-gray-900">
                                    {chip.attributes.serial || '-'}
                                  </div>
                                  <div className="text-xs text-left text-gray-500">
                                    {chip.attributes.number || '-'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="flex-1 truncate">
                                  <div className="text-xs text-left font-semibold text-gray-900">
                                    {chip.attributes.service_provider || '-'}
                                  </div>
                                  <div className="text-xs text-left text-gray-500">
                                    {chip.attributes.carrier || '-'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                              <div className="text-xs text-left font-semibold text-gray-900">
                                {chip.attributes.apn ?? '-'}
                              </div>
                              <div className="text-xs text-left text-gray-500">
                                {`${chip.attributes.apn_username ?? '-'} - ${
                                  chip.attributes.apn_password ?? '-'
                                }`}
                              </div>
                            </td>
                            <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                              <div className="text-sm text-left font-semibold text-gray-900 truncate">
                                <Label
                                  type="item_status"
                                  label={
                                    translations['item_status'][
                                      chip.attributes.item_status as string
                                    ]
                                  }
                                  value={chip.attributes.item_status}
                                />
                              </div>
                              <div className="ml-2 text-xs text-left text-gray-500">
                                {' '}
                                {date(
                                  chip.attributes.item_status_created_at as Date
                                )}{' '}
                              </div>
                            </td>
                            <td className="p-3 whitespace-nowrap text-sm font-medium">
                              {[
                                'ready_for_pick_up',
                                'shipping',
                                'delivered'
                              ].includes(
                                chip.attributes.order_status ?? 'pending'
                              ) && (
                                <ProtectedSection
                                  roles={[Role.MANAGER, Role.FINANCE]}
                                >
                                  {['ordered', 'suspended'].includes(
                                    chip.attributes.item_status ?? ''
                                  ) && (
                                    <button
                                      type="button"
                                      className="relative inline-flex items-center px-3 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                      onClick={() => {
                                        setIndex(idx)
                                        setIsOpen(true)
                                        setIsItemSuspended(
                                          chip.attributes.item_status ===
                                            'suspended'
                                            ? true
                                            : false
                                        )
                                      }}
                                    >
                                      <PencilIcon
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  )}
                                </ProtectedSection>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <Pagination
              pageSize={maxItemsPerPage}
              currentPage={page}
              itemsOnPage={chips.data.length}
              totalItems={total}
              onPageChange={onPageChange}
            />
          </div>
        )}
      </div>

      <UpdateChipStatus
        index={index}
        isOpen={isOpen}
        isItemSuspended={isItemSuspended}
        setOpen={setIsOpen}
      />
      <Info isOpen={isInfoOpen} setIsOpen={setIsInfoOpen} />
    </div>
  )
}
