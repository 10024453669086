import { useViewClient } from 'modules/Clients/hooks/useViewClient'
import { ListInvoices } from 'modules/Invoices'
import { useEffect } from 'react'

export function InvoiceTab() {
  const { client, fetchClient } = useViewClient()

  useEffect(() => {
    if (!client) {
      fetchClient()
    }
  }, [client, fetchClient])

  return (
    <>
      <div className="py-8">
        <ListInvoices clientId={client?.id} />
      </div>
    </>
  )
}
