import { amount, currency, date, time } from 'utils'
import { Client } from 'models'
import { ExternalLinkIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { Invoice } from 'models/invoice'
import { formatYearMonth } from 'modules/Invoices/helpers'
import { ReadResult } from 'interfaces/queryOptions'

interface ReviewTableProps {
  invoice: Partial<Invoice>
  client: Partial<ReadResult<Client>> | undefined
}

export function ReviewTable({ invoice, client }: ReviewTableProps) {
  return (
    <dl className="rounded shadow mx-4 my-2 animate-fade-in-down">
      <div className="bg-gray-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Cliente</dt>
        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <Link
            to={`/clients/${client?.id}/view/general`}
            className="text-gray-900 hover:underline"
          >
            <div className="flex">
              {client?.attributes?.account_name}
              <ExternalLinkIcon
                className="h-5 w-5 text-gray-900 ml-2"
                aria-hidden="true"
              />
            </div>
          </Link>
        </dd>
      </div>
      <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Competência</dt>
        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {formatYearMonth(invoice.billing_period as string)}
        </dd>
      </div>
      <div className="bg-gray-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Valor total</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{`${currency(
          invoice.currency
        )} ${amount(invoice.amount_due, true, true)}`}</dd>
      </div>
      <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">
          Valor restante a pagar
        </dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{`${currency(
          invoice.currency
        )} ${amount(invoice.amount_remaining, true, true)}`}</dd>
      </div>
      <div className="bg-gray-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Valor pago</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{`${currency(
          invoice.currency
        )} ${amount(invoice.amount_paid, true, true)}`}</dd>
      </div>
      <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Fatura paga em</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {invoice.paid_at ? date(invoice.paid_at) : '-'}
        </dd>
      </div>
      <div className="bg-gray-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">
          Data de vencimento
        </dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {invoice.due_at
            ?.toString()
            .substring(0, 10)
            .split('-')
            .reverse()
            .join('/')}
        </dd>
      </div>
      <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Descrição</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {invoice.description ?? '-'}
        </dd>
      </div>
      <div className="bg-gray-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Criado em</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{`${date(
          invoice.created_at
        )} ${time(invoice.created_at)}`}</dd>
      </div>
      <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Atualizado em</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{`${date(
          invoice.updated_at
        )} ${time(invoice.updated_at)}`}</dd>
      </div>
    </dl>
  )
}
