import { useState } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import { FormSection } from 'components/Form'
import { PageHeader } from 'components/PageHeader'

import {
  InlineList,
  InputWithAddon,
  ResizibleTextArea,
  SimpleCustomSelect,
  CheckboxWithDescription,
  InputWithValidationError,
  RadioListWithDescription
} from 'components/FormInputs'
import { Cards } from 'components/FormInputs/Cards'
import {
  accessPlanTypes,
  billingTypes,
  currencies,
  fineModes,
  intervals,
  tierModes,
  yesOrNo
} from 'components/FormInputs/options'
import { TierTable, CreateTier } from '../components/Tiers'

import { Errors } from 'types'
import {
  cleanAmount,
  currency,
  deepClone,
  formatCurrency,
  isNil,
  omitBy
} from 'utils'
import { check } from 'utils/error'
import { validate } from '../validation'
import { AccessPlanForm } from '../types'
import { create } from '../services/create'
import { Plan, UsageType, Currency, Interval, Tier, TierMode } from 'models'
import { getAccessPlanMaxInstallmentsNumber } from 'utils/installments'

export function Create() {
  const history = useHistory()

  const formDataInitialState = {
    currency: Currency.brl,
    duration: 1,
    interval: Interval.month,
    interval_count: 1,
    active: false,
    prorata: true, // for now, prorata always true
    renewable: false,
    restricted: false,
    minimum_invoice_amount: '0,00',
    fine_mode: 'fixed',
    fine_amount: '0,00',
    tier_mode: TierMode.discount,
    setup_fee: '0,00',
    setup_fee_max_installments: 1
  }

  const [formData, setFormData] = useState<AccessPlanForm>(formDataInitialState)

  const [setupFeeInstallmentFriendly, setSetupFeeInstallmentFriendly] =
    useState('no')

  const [tiers, setTiers] = useState<Tier[]>([])
  const [errors, setErrors] = useState<Errors>({})

  const onSave = async () => {
    if (!formData) return

    formData.tiers = tiers

    const transformAttributes = (
      attributes: Record<string, any>
    ): Record<string, any> => {
      const {
        minimum_invoice_amount,
        fine_mode,
        fine_amount,
        tier_mode,
        tiers
      } = attributes

      return {
        minimum_invoice_amount:
          minimum_invoice_amount === ''
            ? null
            : cleanAmount(minimum_invoice_amount),
        fine_mode: fine_amount === '' ? null : fine_mode,
        fine_amount:
          fine_amount === ''
            ? 0
            : fine_mode === null
            ? 0
            : cleanAmount(fine_amount),
        tier_mode:
          tiers === null ? null : tiers?.length === 0 ? null : tier_mode,
        tiers:
          tier_mode === null || (tiers && tiers.length === 0) ? null : tiers
      }
    }

    if (validateFields(formData) || check(errors)) {
      toast.error('Corrija os erros antes de prosseguir')
      return
    }

    const otherAttributes = transformAttributes(formData)

    if (formData.setup_fee === '0,00') {
      delete formData.setup_fee_max_installments
    } else if (
      formData.setup_fee !== '0,00' &&
      (!formData.setup_fee_max_installments ||
        setupFeeInstallmentFriendly === 'no')
    ) {
      formData.setup_fee_max_installments = 1
    }

    try {
      await create(
        omitBy(
          {
            ...formData,
            setup_fee: cleanAmount(formData.setup_fee),
            ...otherAttributes
          },
          isNil
        )
      )
      toast.success('Acesso criado com sucesso')
      history.push('/access-plans')
    } catch (err: any) {
      toast.error(
        err.suggestedMessage ?? 'Houve uma falha ao tentar criar o plano'
      )
    }
  }

  const validateFields = (plan: AccessPlanForm) => {
    if (!plan || !plan.usage_type) return

    let isInvalid = false

    const isNameFilled = !!(
      Object.prototype.hasOwnProperty.call(plan, 'name') && plan.name !== null
    )
    if (!isNameFilled) {
      errors.name = !isNameFilled ? 'O campo deve ser preenchido' : null
      isInvalid = true
    }

    const isDurationFilled = !!(
      Object.prototype.hasOwnProperty.call(plan, 'duration') &&
      plan.duration !== null
    )
    const isIntervalFilled = !!(
      Object.prototype.hasOwnProperty.call(plan, 'interval_count') &&
      plan.interval_count !== null
    )

    if (!isDurationFilled || !isIntervalFilled) {
      errors.amount = !isIntervalFilled ? 'O campo deve ser preenchido' : null
      errors.duration = !isDurationFilled ? 'O campo deve ser preenchido' : null
      isInvalid = true
    }

    if (plan.usage_type === UsageType.licensed) {
      const isAmountFilled = !!(
        Object.prototype.hasOwnProperty.call(plan, 'amount') &&
        plan.amount !== null
      )
      errors.amount = !isAmountFilled ? 'O campo deve ser preenchido' : null
    }

    if (plan.usage_type === UsageType.tiered && tiers.length <= 0) {
      errors.tiers = 'Adicione faixas antes de continuar'
      isInvalid = true
    }

    setErrors({ ...errors })

    return isInvalid
  }

  const onAttributeChange = (
    attr: keyof Plan | keyof Partial<AccessPlanForm>,
    value: any
  ) => {
    if (!formData) return
    if (attr === 'usage_type') {
      setFormData({ ...formDataInitialState, [attr]: value })
      setTiers([])
      setErrors({})

      return
    }

    if (attr === 'fine_amount' || attr === 'setup_fee') {
      value = formatCurrency(value)
    }

    const error = validate(attr as any, value)

    setFormData({ ...formData, [attr]: value })
    setErrors({ ...errors, [attr]: error })
  }

  return (
    <>
      <PageHeader title="Planos de acesso" action="Novo" />

      <div className="flex flex-row space-x-8">
        <div className="w-3/5 px-4">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSave()
            }}
          >
            <FormSection title="Novo plano de acesso">
              <Cards
                value={formData?.usage_type}
                options={deepClone(accessPlanTypes)}
                onChange={(type) => onAttributeChange('usage_type', type)}
              />

              {formData.usage_type && (
                <>
                  <InputWithValidationError
                    label="Nome"
                    name="name"
                    value={formData?.name}
                    onChange={(name) => onAttributeChange('name', name)}
                    error={errors.name}
                  />

                  <ResizibleTextArea
                    label="Descrição"
                    name="description"
                    value={formData?.description}
                    onChange={(description) =>
                      onAttributeChange('description', description)
                    }
                  />

                  <fieldset className="space-y-5">
                    <legend className="text-left">
                      Estado de disponibilidade
                    </legend>
                    <CheckboxWithDescription
                      label="Ativado"
                      name="active"
                      checked={formData?.active ?? false}
                      onChange={(active) => onAttributeChange('active', active)}
                      description="Ao ativar o plano, todo o time da Softruck será notificado sobre sua criação ou edição."
                    />
                  </fieldset>

                  <fieldset className="space-y-5">
                    <legend className="text-left">Extensão de prazos</legend>
                    <CheckboxWithDescription
                      label="Renovável"
                      name="renewable"
                      checked={formData?.renewable ?? false}
                      onChange={(renewable) =>
                        onAttributeChange('renewable', renewable)
                      }
                      description="Se o plano permite renovação este campo deverá ser ativado, lembrando que comodatos de rastreadores não devem ter essa condição como ativada"
                    />
                  </fieldset>
                </>
              )}
            </FormSection>

            {
              <div className=" animate-fade-in-down">
                <FormSection title="Valores e Períodos">
                  {[UsageType.licensed, UsageType.metered].includes(
                    formData.usage_type!
                  ) && (
                    <div className="flex flex-row space-x-8">
                      <div className="w-1/3">
                        <SimpleCustomSelect
                          label="Moeda"
                          options={currencies}
                          value={formData.currency ?? Currency.brl}
                          onChange={(currency) =>
                            onAttributeChange('currency', currency)
                          }
                        />
                      </div>

                      <div className="w-1/3">
                        <InputWithAddon
                          label="Valor"
                          name="amount"
                          type="text"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          value={formData.amount ?? ''}
                          frontAddon={currency(formData?.currency)}
                          onChange={(value) =>
                            onAttributeChange('amount', value)
                          }
                          error={errors.amount}
                        />
                      </div>
                    </div>
                  )}
                  {formData.usage_type === UsageType.tiered && (
                    <>
                      <div className="w-1/3">
                        <SimpleCustomSelect
                          label="Moeda"
                          options={currencies}
                          value={formData?.currency}
                          onChange={(currency) =>
                            onAttributeChange('currency', currency)
                          }
                        />
                      </div>

                      <InlineList
                        label="Modo de faixas"
                        description="Definem como será feita a cobrança em cada faixa."
                        options={tierModes}
                        onChange={(tierMode) =>
                          onAttributeChange('tier_mode', tierMode)
                        }
                      />

                      <CreateTier
                        selectedCurrency={formData?.currency}
                        tiers={tiers}
                        setTiers={setTiers}
                        errors={errors}
                        setErrors={setErrors}
                      />
                    </>
                  )}
                  <RadioListWithDescription
                    label="Modo de Fatura"
                    options={billingTypes}
                    value={formData?.billing_type}
                    onChange={(billingType) =>
                      onAttributeChange('billing_type', billingType)
                    }
                  />

                  <div className="flex flex-row space-x-8">
                    <div className="w-1/3">
                      <InputWithValidationError
                        label="Duração"
                        name="duration"
                        value={formData?.duration}
                        onChange={(duration) =>
                          onAttributeChange('duration', duration)
                        }
                        error={errors.duration}
                      />
                    </div>
                    <div className="w-1/3">
                      <SimpleCustomSelect
                        label="Cobrança"
                        options={intervals}
                        value={formData?.interval}
                        onChange={(interval) =>
                          onAttributeChange('interval', interval)
                        }
                      />
                    </div>
                    <div className="w-1/3">
                      <InputWithValidationError
                        label="Intervalo"
                        name="interval_count"
                        value={formData?.interval_count}
                        onChange={(intervalCount) =>
                          onAttributeChange('interval_count', intervalCount)
                        }
                        error={errors.interval_count}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row space-x-8">
                    <div className="w-1/3">
                      <InputWithAddon
                        label="Taxa de adesão"
                        name="setup_fee"
                        type="text"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        value={formData?.setup_fee}
                        frontAddon={currency(formData?.currency)}
                        onChange={(value) =>
                          onAttributeChange('setup_fee', value)
                        }
                      />
                    </div>

                    {formData.setup_fee !== '0,00' && (
                      <div className="w-1/3">
                        <SimpleCustomSelect
                          label="Parcelável"
                          options={yesOrNo}
                          value={setupFeeInstallmentFriendly}
                          onChange={(value) =>
                            setSetupFeeInstallmentFriendly(value)
                          }
                        />
                      </div>
                    )}

                    {formData.setup_fee !== '0,00' && (
                      <div className="w-1/3">
                        <InputWithValidationError
                          label="Número máximo de parcelas"
                          type="number"
                          name="quantity"
                          min="0"
                          max={getAccessPlanMaxInstallmentsNumber(
                            formData.duration as number,
                            formData.interval as string
                          )}
                          value={formData?.setup_fee_max_installments ?? 1}
                          onChange={(value) =>
                            onAttributeChange(
                              'setup_fee_max_installments',
                              value
                            )
                          }
                          error={errors.quantity}
                          onWheel={(e: React.FocusEvent<HTMLInputElement>) =>
                            e.target.blur()
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="w-1/3">
                    <InputWithAddon
                      label="Valor mínimo da fatura"
                      name="minimum_invoice_amount"
                      type="text"
                      pattern="[0-9]+([\.,][0-9]+)?"
                      value={formData?.minimum_invoice_amount}
                      frontAddon={currency(formData?.currency)}
                      onChange={(value) =>
                        onAttributeChange('minimum_invoice_amount', value)
                      }
                    />
                  </div>

                  <div className="flex flex-row space-x-8">
                    <div className="w-1/3">
                      <SimpleCustomSelect
                        label="Tipo de multa"
                        options={fineModes}
                        value={formData?.fine_mode}
                        onChange={(fineModes) =>
                          onAttributeChange('fine_mode', fineModes)
                        }
                      />
                    </div>

                    <div className="w-1/3">
                      <InputWithAddon
                        label="Valor da multa"
                        name="fine_amount"
                        type="text"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        value={formData?.fine_amount}
                        frontAddon={
                          formData.fine_mode === 'fixed'
                            ? currency(formData?.currency)
                            : '%'
                        }
                        onChange={(value) =>
                          onAttributeChange('fine_amount', value)
                        }
                      />
                    </div>
                  </div>
                </FormSection>

                <FormSection title="Restrições">
                  <fieldset className="space-y-5">
                    <legend className="text-left">
                      Aplicação de descontos
                    </legend>
                    <CheckboxWithDescription
                      label="Restrito"
                      name="restricted"
                      checked={formData?.restricted ?? false}
                      onChange={(restricted) =>
                        onAttributeChange('restricted', restricted)
                      }
                      description="Planos restritos não podem ser aplicados nenhum tipo de desconto"
                    />
                  </fieldset>

                  <fieldset className="space-y-5" disabled>
                    <legend className="text-left">Pro rata</legend>
                    <CheckboxWithDescription
                      label="Pro rata"
                      name="prorata"
                      checked={formData?.prorata ?? false}
                      onChange={(prorata) =>
                        onAttributeChange('prorata', prorata)
                      }
                      description="Planos com pro rata levarão em conta cada dia de utilização na geração das faturas"
                      readOnly
                    />
                  </fieldset>
                </FormSection>
              </div>
            }
            {formData.usage_type && (
              <div className="mt-12 flex justify-end animate-fade-in-down">
                <button
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                  onClick={() => history.goBack()}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                >
                  Salvar
                </button>
              </div>
            )}
          </form>
        </div>

        <div className="w-1/3 relative animate-fade-in-down">
          <div className="sticky top-0">
            {tiers.length > 0 && (
              <div className="pt-10">
                <TierTable
                  currency={formData?.currency ?? Currency.brl}
                  tiers={tiers}
                  setTiers={setTiers}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
