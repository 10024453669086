export enum InvoiceStatus {
  draft = 'draft',
  open = 'open',
  paid = 'paid',
  uncollectible = 'uncollectible',
  canceled = 'canceled'
}

export interface Invoice {
  id: number
  client_id: number
  identifier: string
  currency: string
  amount_due: number
  amount_paid: number
  amount_remaining: number
  due_at: Date
  billing_period: string
  description?: string
  created_at: Date
  updated_at: Date
  deleted_at?: Date
  paid_at?: Date
  relationships?: Record<string, any>
}
